<template>
    <div class="vStudioList">
      <perfect-scrollbar ref="scroll">
        <ul class="keys">
          <li v-for="(list, name) in studioList" :key="name">
            <span>{{name}}</span>
            <ul class="studios">
              <li v-for="studio in list" v-bind:key="studio.id" @click="updateSelection(studio)" :class="{selected:studio == selectedStudio}">
                {{ studio.name }}
                <span v-if="studio.isPresale && !studio.isOpening" class="badge bg-dark rounded-pill text-white px-3 d-inline-block ml-2 text-uppercase">Opening Soon</span>
                <span v-if="studio.isOpening" class="badge bg-dark rounded-pill text-white px-3 d-inline-block ml-2 text-uppercase">{{ $t('common.opening', {date: getDate(studio.isOpening)}) }}</span>
                <span v-if="studio.tagText" class="badge bg-dark rounded-pill text-white px-3 d-inline-block ml-2 text-uppercase">{{ studio.tagText }}</span>
              </li>
            </ul>
          </li>
        </ul>
      </perfect-scrollbar>
    </div>
</template>

<script>
import $ from "jquery/dist/jquery.min"
import moment from "moment";

export default {
  name: "StudioList.vue",
  props: ["Studios", "selectedStudio"],
  data(){
    return{
        studioList: {},
        studioChanged: false,
        studioChangedByClick: false,
    }
  },
  watch:{
    Studios(){
      this.studioList = {};
      this.Studios.forEach(studio => {
        let key = studio.name.substring(0, 1);
        this.studioList[key] = ( typeof this.studioList[key] != 'undefined' && this.studioList[key] instanceof Array ) ? this.studioList[key] : [];
        this.studioList[key].push(studio);
      });
    },
    selectedStudio(){
      this.studioChanged = true;
    }
  },
  updated(){
    let $selected =  $(this.$el).find('.selected');
    if(this.studioChanged && !this.studioChangedByClick && $selected.length){
        let pos = $selected.position();
        let parent = $selected.parents("ul").position();

        this.$refs.scroll.$el.scrollTop = this.$refs.scroll.$el.scrollTop  + pos.top;
      console.log('!Here?')
        this.studioChanged = false;
    }
    else if(this.studioChanged && this.studioChangedByClick) {
      this.studioChanged = false;
      this.studioChangedByClick = false;
    }
  },
  methods: {
    getDate(date){
      return moment(date).format("DD.MM.YYYY");
    },
    updateSelection: function (selectedStudio) {
      this.studioChangedByClick = true;
      if (typeof window.ga !== 'undefined'){window.ga('send', 'event', 'Bestellung', 'Club gewählt', selectedStudio.name);}
      this.$emit('clicked', selectedStudio);
    }
  }
}
</script>

<style scoped>

</style>