<template>
  <div class="vCheckout1">
    <h1 class="text-center">{{ $t('pages.selectData.headline') }}</h1>
    <steps :steps="[]" />

    <ValidationObserver ref="form" v-slot="{ invalid }">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="hr mx-0"><span>{{ $t('pages.checkOut.yourData') }}</span></div>

          <div class="row my-3">
            <div class="col">
              <label class="radio">
                  <input type="radio" name="gender" v-model="gender" value="F"><span></span> {{ $t('widget.user.genderFemale') }}
              </label>
              <label class="radio">
                <input type="radio" name="gender" v-model="gender" value="M"><span></span> {{ $t('widget.user.genderMale') }}
              </label>
              <label class="radio">
                <input type="radio" name="gender" v-model="gender" value="D"><span></span> {{ $t('widget.user.genderDivers') }}
              </label>
            </div>
          </div>

          <div class="row my-2">
              <div class="col-md-6 pr-md-1">
                <div class="form-group">
                  <ValidationProvider name="fname" rules="required" v-slot="v">
                  <input class="form-control" type="text" v-model="$store.state.user.fName" :class="{filled:fName}" maxlength="50" required />
                  <label>{{ $t('widget.user.firstname') }}</label>
                  <span class="error" v-if="v.errors">{{ v.errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-md-6 pl-md-1">
                <div class="form-group">
                  <ValidationProvider name="lname" rules="required" v-slot="v">
                  <input class="form-control" type="text" v-model="lName" :class="{filled:lName}" maxlength="50" required />
                  <label>{{ $t('widget.user.lastname') }}</label>
                  <span class="error" v-if="v.errors">{{ v.errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
          </div>
          <div class="row my-2">
            <div class="col">
              <div class="form-group">
                <ValidationProvider name="email" rules="required|email" v-slot="v">
                  <input class="form-control" type="email" v-model="email" maxlength="50" :class="{filled:email}" required >
                  <label>{{ $t('widget.user.email') }}</label>
                  <span class="error" v-if="v.errors">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="row my-2">
            <div class="col-md-8 pr-md-1">
              <div class="form-group">
                <ValidationProvider name="phone" rules="required" v-slot="v">
                  <VuePhoneNumberInput default-country-code="CH" :noFlags="false" :noExample="true" :noCountrySelector="false" :only-countries="['CH', 'DE', 'FR', 'AT', 'IT']" :translations="translation" :placeholder="Test" maxlength="12" v-model="phone" :class="{filled:phone}"  />
                  <span class="error" v-if="v.errors">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-4 pl-md-1">
              <div class="form-group">
                <ValidationProvider name="birthday" rules="required" v-slot="v">
                  <date-picker v-model="birthday" @dp-change="birthdayChanged" :config="dateOptionsBirthday" :class="{filled:birthday}" :disabled="false" required></date-picker>
                  <label>{{ $t('widget.user.birthday') }}</label>
                  <span class="error" v-if="v.errors">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div v-if="isMinior()">
            <div class="hr mx-0"><span>{{  $t('widget.miniors.headline') }}</span></div>
            <div class="row my-1">
              <div class="col">
                <ol class="minors">
                  <li>{{ $t('widget.miniors.minors1') }}</li>
                  <li>
                    <i18n path="widget.miniors.minors2" tag="span">
                      <template v-slot:Formular>
                        <a :href="$t('widget.miniors.formUrl')" target="_blank">{{ $t('widget.miniors.form') }}</a>
                      </template>
                    </i18n>
                  </li>
                  <li>{{ $t('widget.miniors.minors3') }}</li>
                </ol>
              </div>
            </div>
          </div>
          <div class="h-100 d-flex flex-column">
            <div class="hr mx-0 d-none d-sm-block"><span>{{ $t('pages.selectData.dataProtection') }}</span></div>
            <div class="row my-2">
              <div class="col">
                <label class="checkbox">

                    <input type="checkbox" name="check1" v-model="check1" value="F"><i></i>
                    <i18n path="pages.selectData.checkboxDataPolice" tag="span">
                      <template v-slot:DP_URL>
                        <a :href="$root.config.urlPrivacy" target="_blank">{{ $t('pages.selectData.dataProtection') }}</a>
                      </template>
                    </i18n>

                </label>
              </div>
            </div>

            <!--<div class="row my-2">
              <div class="col">
                <label class="checkbox" >
                  <input type="checkbox" name="gender" v-model="check2" value="F"><i></i>
                  {{ $t('pages.selectData.checkboxNewsletter') }}
                </label>
              </div>
            </div>-->
            <div class="row d-none d-sm-block">
                <div class="col mt-3">
                    <button class="btn btn-primary btn-block btn-xxl" @click="$store.commit('NEXT_STEP')" :disabled="invalid || !check1">
                      {{  $t('common.next') }}</button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </ValidationObserver>
    <footer-link-bar></footer-link-bar>
    <Footer :button-only-mobile="true"  @clickNext="$store.commit('NEXT_STEP')" :button-enabled="!invalid && check1" :headline="$t('pages.selectData.yourData')" :subheadline="$t('pages.selectData.footerActionText')"></Footer>
  </div>
</template>

<script>
import { EventBus } from '@/event-bus';
import moment from "moment";

import Footer from "@/components/Footer";

import Steps from "@/components/StepsPart2";
import { extend } from 'vee-validate';
import { required, email } from "vee-validate/dist/rules";
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import FooterLinkBar from "@/components/FooterLinkBar";

extend('email', email);
extend('required', required);

export default {
  name: "Checkout1.vue",
  components: {Steps, VuePhoneNumberInput, Footer,FooterLinkBar},
  computed:{
      fName:{
        set(nValue, oValue){
            this.$store.commit("SET_USER_DATA",{ fName: nValue });
        },
        get(){
            return this.$store.state.user.fName;
        }
      },
      lName:{
        set(nValue, oValue){
          this.$store.commit("SET_USER_DATA",{ lName: nValue });
        },
        get(){
          return this.$store.state.user.lName;
        }
      },
      email:{
        set(nValue, oValue){
          this.$store.commit("SET_USER_DATA",{ email: nValue });
        },
        get(){
          return this.$store.state.user.email;
        }
      },
      phone:{
        set(nValue, oValue){
          this.$store.commit("SET_USER_DATA",{ phone: nValue });
        },
        get(){
          return this.$store.state.user.phone;
        }
      },
      birthday:{
        set(nValue, oValue){
          this.$store.commit("SET_USER_DATA",{ birthday: nValue });
        },
        get(){
          return this.$store.state.user.birthday;
        }
      },
      gender:{
        set(nValue, oValue){
          this.$store.commit("SET_USER_DATA",{ gender: nValue });
        },
        get(){
            return this.$store.state.user.gender;
        }
      }
  },
  mounted(){
    let max = new Date();
    max.setFullYear(max.getFullYear()-16);
    this.dateOptionsBirthday.maxDate = max;

    let min = new Date();

    min.setFullYear(min.getFullYear()-115);
    this.dateOptionsBirthday.minDate = min;

    this.$store.commit("SET_STEP", {step:4});

  },
  methods: {
      isMinior(){
          if(!this.birthday) return false;

          let today = moment();
          let birthday = moment(this.birthday, "DD.MM.YYYY");
          let years = moment.duration(today.diff(birthday)).asYears();

          //alert(years);

          return years < 18;
      },
      birthdayChanged(v1, v2){
        //console.log(v1, v2);
      },
      check(){
        if(!this.fName){
            return true;
        }

        if(!this.lName){
          return true;
        }

        if(!this.email){
          return true;
        }

        if(!this.check1){
          return true;
        }

        return false;
      }
  },
  data() {
    return {
      translation: { countrySelectorLabel: 'Ländercode', countrySelectorError: 'Choose country 1', phoneNumberLabel: 'Telefonnummer', example: 'Bsp:' },
      check1: false,
      check2: false,
      dataProtectionURL: "https://basefit.ch",
      dateOptionsBirthday: {
        format: 'DD.MM.YYYY',
        maxDate: null,
        useCurrent: false,
        locale: 'de-de',
        viewMode: 'decades',
        debug: false,
      }
    };
  }
}
</script>

<style scoped>

</style>