<template>
  <div class="vCheckout2">

    <h1 class="text-center">{{ $t('pages.selectAddress.headline') }}</h1>
    <steps :steps="[]" />

    <ValidationObserver ref="form" v-slot="{ invalid }">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-7">
          <div class="h-100 d-flex flex-column">
            <div class="hr mx-0"><span>{{  $t('pages.selectAddress.yourAddress') }}</span></div>

            <div class="row my-1">
              <div class="col-md-9 pr-md-1">
                <div class="form-group">
                  <ValidationProvider name="street" rules="required" v-slot="v">
                      <input class="form-control" type="text" v-model="street" :class="{filled:street}" maxlength="60" required>
                      <label>{{  $t('widget.address.street') }}</label>
                      <span class="error" v-if="v.errors">{{ v.errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col pl-md-1">
                <div class="form-group">
                  <ValidationProvider name="streetNo" rules="required" v-slot="v">
                  <input class="form-control" type="text" v-model="streetNo" :class="{filled:streetNo}" maxlength="15" required>
                  <label>{{  $t('widget.address.streetno') }}</label>
                    <span class="error" v-if="v.errors">{{ v.errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="row my-1">
              <div class="col-md-4 pr-md-1">
                <div class="form-group">
                  <ValidationProvider name="zipcode" rules="required|numeric" v-slot="v">
                  <input class="form-control" type="text" v-model="zipcode" maxlength="4" :class="{filled:zipcode}" required>
                  <label>{{  $t('widget.address.zipcode') }}</label>
                    <span class="error" v-if="v.errors">{{ v.errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col pl-md-1">
                <div class="form-group">
                  <ValidationProvider name="city" rules="required" v-slot="v">
                  <input class="form-control" type="text" v-model="city" :class="{filled:city}" maxlength="30" required>
                  <label>{{  $t('widget.address.city') }}</label>
                    <span class="error" v-if="v.errors">{{ v.errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="h-100 d-flex flex-column">
            <div class="hr mx-0"><span>{{  $t('pages.selectAddress.yourMembership') }}</span></div>
            <div class="row my-1">
              <div class="col">
                <div class="form-group">
                  <date-picker v-model="membership" :config="dateOptionsMembership" class="active" :disabled="$store.state.order.membership.disabled" style="opacity: 0"></date-picker>
                  <label>{{  $t('widget.membership.startOfMembership') }}*</label>
                  <div class="form-control"><strong>{{ membership }}</strong> ({{$t('widget.membership.trainingsStart', {date:getTrainingsStart()})}})</div>
                </div>
              </div>
            </div>
            <!--<div class="row my-1">
              <div class="col">
                <div class="form-group">
                  <input class="form-control" type="text" v-model="user.fname" :class="{filled:user.fname}" required>
                  <label>Woher kennst du basefit.ch?</label>
                </div>
              </div>
            </div>-->
            <div class="row d-none d-sm-block flex-grow-1 my-1">
              <div class="col">
                <button class="btn btn-primary btn-block btn-xxl" @click="next()" :disabled="invalid">{{  $t('common.next') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    </ValidationObserver>

    <div v-if="recommendations" class="container">
      <div class="row">
        <div class="col">
          <div class="hr mx-0"><span>Empfehlungen</span></div>
        </div>
      </div>
      <div class="row" v-for="recommendation in recommendations" :key="recommendation">
        <div class="col">
          <div class="form-group">
            <ValidationProvider name="fname" rules="required" v-slot="v">
              <input class="form-control" type="text" v-model="recommendation.fName" :class="{filled:recommendation.fName}" maxlength="50" required />
              <label>{{ $t('widget.user.firstname') }}</label>
              <span class="error" v-if="v.errors">{{ v.errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <ValidationProvider name="fname" rules="required" v-slot="v">
              <input class="form-control" type="text" v-model="recommendation.lName" :class="{filled:recommendation.lName}" maxlength="50" required />
              <label>{{ $t('widget.user.lastname') }}</label>
              <span class="error" v-if="v.errors">{{ v.errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <ValidationProvider name="phone" rules="required" v-slot="v">
              <VuePhoneNumberInput default-country-code="CH" :noFlags="false" :noExample="true" :noCountrySelector="false" :only-countries="['CH', 'DE', 'FR', 'AT', 'IT']" :translations="translation" :placeholder="0" maxlength="12" v-model="recommendation.phone" :class="{filled:recommendation.phone}"  />
              <span class="error" v-if="v.errors">{{ v.errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col text-right">
          <button @click="moreRecommendations()" class="btn btn-dark"><i class="fas fa-user-plus"></i> weitere Empfehlung</button>
        </div>
      </div>
    </div>

    <footer-link-bar></footer-link-bar>
    <Footer :button-only-mobile="true"  @clickNext="next()" :button-enabled="!invalid" :headline="$t('pages.selectAddress.footerName', {fname: $store.state.user.fName})" :subheadline="$t('pages.selectData.footerActionText')"></Footer>
  </div>
</template>

<script>
import {mapState} from "vuex";
import moment from "moment";
import Steps from "@/components/StepsPart2";
import Footer from "@/components/Footer";
import FooterLinkBar from "@/components/FooterLinkBar";

import { extend } from 'vee-validate';
import { required, numeric} from "vee-validate/dist/rules";
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import helper from "@/facebook";
extend('required', required);
extend('numeric', numeric);


export default {
  name: "Checkout2",
  components: {Steps, Footer, FooterLinkBar, VuePhoneNumberInput},
  computed: {
    ...mapState(["contract", "order"]),
    street:{
      set(nValue, oValue){this.$store.commit("SET_USER_DATA",{ street: nValue });},
      get(){return this.$store.state.user.street;}
    },
    streetNo:{
      set(nValue, oValue){this.$store.commit("SET_USER_DATA",{ streetNo: nValue });},
      get(){return this.$store.state.user.streetNo;}
    },
    zipcode:{
      set(nValue, oValue){this.$store.commit("SET_USER_DATA",{ zipcode: nValue });},
      get(){return this.$store.state.user.zipcode;}
    },
    city:{
      set(nValue, oValue){this.$store.commit("SET_USER_DATA",{ city: nValue });},
      get(){return this.$store.state.user.city;}
    },
    membership:{
      set(nValue, oValue){this.$store.commit("SET_MEMBERSHIP",nValue);},
      get(){return this.$store.state.order.membership.value;}
    },
    dateOptionsMembership(){
      let options = {
        format: this.$store.state.order.membership.format,
        minDate: this.$store.state.order.membership.minDate,
        maxDate: this.$store.state.order.membership.maxDate,
        useCurrent: false,
        locale: 'de-de',
      }

      switch(this.$i18n.locale){
        case 'fr':
          options.locale = "fr-fr";
          break;
        case 'it':
          options.locale = "it-it";
          break;
        case 'en':
          options.locale = "en-gb";
          break;
        default:
          options.locale = "de-de";
      }


      options.locale = this.$i18n.locale;

      /*if(this.contract.vertLaufzeit > 3){
          options.format = '01.MM.YYYY';
          options.minDate = '2021-04-01';
      }*/

      return options;
    }
  },
  mounted() {

    if(this.$root.config.modus != undefined && this.$root.config.modus == "employerApp" && !this.recommendations){
      this.recommendations = [];
        this.recommendations.push({
          fName: null,
          lName: null,
          phone: null,
        });
    }
    else{
      this.recommendations = false;
    }


    this.$store.commit("SET_STEP", {step:5});
  },
  methods: {
    moreRecommendations(){
      this.recommendations.push({
        fName: null,
        lName: null,
        phone: null,
      });
    },
    next(){
      helper.fbq('track', 'AddToCart', { currency: 'CHF',});
      this.$store.dispatch('setCustomer', {data: this.$store.state.user, membership: this.$store.state.order.membership.value});

      if(this.recommendations){
        this.$store.dispatch('setRecommendations', {recommendations: this.recommendations});
      }

      this.$store.commit('NEXT_STEP');
    },
    check(){
      return false;
    },
    getTrainingsStart(){
      //alert(this.$store.state.order.accessdate.value);

      /*if(this.$store.state.order.membership.format == "DD.MM.YYYY"){
        return this.$store.state.order.accessdate.value;
      }*/

      if(this.$store.state.order.membership.value > this.$store.state.order.membership.defaultValue){
        return this.$store.state.order.membership.value;
      }

      return this.$store.state.order.accessdate.value;




      /*
      if(this.$store.state.studio.id != 25) {
        return this.membership;
      }

      if(this.membership > this.$store.state.order.membership.defaultValue){
        return this.membership;
      }
      return this.$t('widget.membership.rightAway');*/
    }
  },
  data(){
    return{
      translation: { countrySelectorLabel: 'Ländercode', countrySelectorError: 'Choose country 1', phoneNumberLabel: 'Telefonnummer', example: 'Bsp:' },
      coreHover: false,
      premiumHover: false,
      invalid: false,
      check1: false,
      check2: false,
      recommendations:false,
    }
  }
}
</script>

<style scoped>

</style>