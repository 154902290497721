import Vue from 'vue'
import vuex from 'vuex'
import states from "./states"
import actions from "./actions"
import getters from "./getters"
import mutations from "./mutations"

Vue.use(vuex);

//const debug = process.env.NODE_ENV !== 'production'
const debug = false;

export default new vuex.Store({
    state: states,
    actions,
    mutations,
    getters,
   /* modules: {
        cart,
        products
    },*/
    strict: debug,
    //plugins: debug ? [createLogger()] : []
})