<template>
  <div>
    <h1 class="text-center mb-5">Erfolgreich online registriert</h1>
      <div class="container">
        <div class="row">
          <div class="col">
            <p>Herzlichen Glückwunsch zur Mitgliedschaft</p>

            <p>Du wirst eine E-Mail mit Deinen detaillierten Vertragsdaten erhalten.</p>
            <p>Natürlich kannst du noch heute während unseren Geschäftszeiten dein Mitgliedsbadge abholen und gleich lostrainieren.</p>

            <button class="mt-5 btn btn-primary btn-xxl" @click="reset()">Neue Bestellung durchführen</button>
          </div>
        </div>
      </div>
  </div>
</template>

<script>




export default {
  name: "ThankYou",
  computed: {

  },
  data(){
    return{
      errors: false,
      user: null,
      password: null,
    }
  },
  watch:{

  },
  mounted(){
  },
  methods: {
    reset(){
      this.$store.commit('reset');
      this.$router.push('login');
    }
  }
}

</script>

<style scoped>

</style>