import Vue from 'vue'
import VueI18n from "vue-i18n";
import de from '@/locales/de'
import en from '@/locales/en'
import fr from '@/locales/fr'
import it from '@/locales/it'
import axios from 'axios'


Vue.use(VueI18n);


// setup locale info for root Vue instance
const i18n =  new VueI18n({
    locale: 'de', // set locale
    //fallbackLocale: 'de',
    messages: {
        de:de,
        en:en,
        fr:fr,
        it:it,
    }
});

export default i18n;
