<template>
  <div>
    <h1 class="text-center mb-5">{{ $t("pages.failed.headline") }}</h1>
    <div class="container">
      <div class="row">
        <div class="col">
          <p>{{ $t("pages.failed.intro") }}</p>

          <i18n path="pages.failed.newOrder" tag="p" >
            <template v-slot:url>
              <a href="#" @click.stop="reset()" style="font-weight: bold; text-decoration: underline;">{{ $t("pages.failed.here")  }}</a>
            </template>
          </i18n>
        </div>
      </div>
    </div>
  </div>
</template>

<script>




export default {
  name: "S3_P2_Failed",
  computed: {
  },
  data(){
    return{
      errors: false,
      user: null,
      password: null,
    }
  },
  watch:{

  },
  mounted(){
  },
  methods: {
    reset(){
      this.$store.commit('reset');
      this.$router.push('login');
    }
  }
}

</script>

<style scoped>

</style>