<template>
  <div class="container px-0" style="margin-top: 60px; margin-bottom: 110px;">
    <div  class="row footer" >
      <div class="col">
        <a :href="$root.config.urlHome"><font-awesome-icon icon="arrow-left" /> {{ $t('footer.backToHomepage') }}</a>
      </div>
      <div class="col-auto text-right">
        <a :href="$root.config.urlPrivacy">{{ $t('footer.dataProtection') }}</a>
      </div>
      <div class="col-auto text-right">
        <a :href="$root.config.urlImprint">{{ $t('footer.imprint') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterLinkBar"
}
</script>

<style scoped>

</style>