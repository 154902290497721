import { render, staticRenderFns } from "./S1_P3_Additions.vue?vue&type=template&id=82c32dac&scoped=true&"
import script from "./S1_P3_Additions.vue?vue&type=script&lang=js&"
export * from "./S1_P3_Additions.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "82c32dac",
  null
  
)

export default component.exports