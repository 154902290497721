import Vue from "vue";
import axios from "axios";

const init = ({commit,state}) => {
    if(window.shopconfig.modus != undefined &&  window.shopconfig.modus == "employerApp"){
        commit("SET_STEP", {step: 0});
        return;
    }
    Vue.axios.get("/api/shop/order/restore", {params:{lang: state.locale}}).then(res =>{
        //commit("SET_STEP", {step: res.data.state});

        //let step = res.data.state;

        //if(step > 1) step = 0;
        //step = 0;
        //commit("SET_STEP", {step: step});
        commit("SET_STUDIO", {studio: res.data.studio});
        commit("SET_CONTRACT", {contract: res.data.contract});
        commit("SET_ADDITION", {addition: res.data.addition});
        commit("SET_USER_DATA", res.data.customer);
        //commit("SET_PAYMENT", {addition: res.data.addition});
    });
};



const setStudio = ({commit, state}, { studio}) => {
    commit("SET_STUDIO", {studio: studio});

    Vue.axios.post('/api/shop/order/studio', {studio: state.studio, state: state.currentStep}, {params:{lang: state.locale}} ).then(res => {
        commit("SET_BACKOFFICE", {basket: res.data.basket, order: res.data.order});
        localStorage.setItem('X-MEN', res.data.token);
        axios.defaults.headers.common['X-MEN'] = localStorage.getItem('X-MEN');
    }).catch(error => {alert("Ein Fehler ist aufgetreten"); });
};

const setContract = ({commit, state}, { contract}) => {
    commit("SET_CONTRACT", {contract: contract});

    Vue.axios.post('api/shop/order/contract', {contract: state.contract, state: state.currentStep}, {params:{lang: state.locale}}).then(res => {
        commit("SET_BACKOFFICE", {basket: res.data.basket, order: res.data.order});
        localStorage.setItem('X-MEN', res.data.token);
        axios.defaults.headers.common['X-MEN'] = localStorage.getItem('X-MEN');

    }).catch(error => {alert("Ein Fehler ist aufgetreten"); });
};

const setAddition = ({commit, state}, { addition}) => {
    commit("SET_ADDITION", {addition: addition});

    Vue.axios.post('api/shop/order/addition', {addition: state.addition, state: state.currentStep}, {params:{lang: state.locale}}).then(res => {
        commit("SET_BACKOFFICE", {basket: res.data.basket, order: res.data.order});
        localStorage.setItem('X-MEN', res.data.token);
        axios.defaults.headers.common['X-MEN'] = localStorage.getItem('X-MEN');
    }).catch(error => {alert("Ein Fehler ist aufgetreten"); });
};

const setCustomer = ({commit, state}, {customer,membership}) => {
    commit("SET_USER_DATA", {data: customer});
    Vue.axios.post('api/shop/order/customer', {customer: state.user, state: state.currentStep, membership: state.order.membership.value}, {params:{lang: state.locale}}).then(res => {

        commit("SET_BACKOFFICE", {basket: res.data.basket, order: res.data.order});
        localStorage.setItem('X-MEN', res.data.token);
        axios.defaults.headers.common['X-MEN'] = localStorage.getItem('X-MEN');

    }).catch(error => {alert("Ein Fehler ist aufgetreten"); });
};

const setRecommendations = ({commit, state}, {recommendations}) => {
    console.log("Action:", recommendations);
    commit("SET_RECOMMENDATIONS", {data: recommendations});
    Vue.axios.post('api/shop/order/recommendations', {recommendations: state.recommendations}, {params:{lang: state.locale}}).then(res => {
        localStorage.setItem('X-MEN', res.data.token);
        axios.defaults.headers.common['X-MEN'] = localStorage.getItem('X-MEN');

    }).catch(error => {alert("Ein Fehler ist aufgetreten"); });
};

const setPayment = ({commit, state}, { customer}) => {
    commit("SET_USER_DATA", {data: customer});

    Vue.axios.post('api/shop/order/customer', {customer: state.user, state: state.currentStep}, {params:{lang: state.locale}}).then(res => {
        commit("SET_BACKOFFICE", {basket: res.data.basket, order: res.data.order});
        localStorage.setItem('X-MEN', res.data.token);
        axios.defaults.headers.common['X-MEN'] = localStorage.getItem('X-MEN');

    }).catch(error => {alert("Ein Fehler ist aufgetreten"); });
};

const restoreSession = (state) => {
    alert('restore');
};

const refreshBasket = (state) => {
    alert('refresh');
};

export default {
    init,
    setStudio,
    setContract,
    setAddition,
    setCustomer,
    setPayment,
    setRecommendations,
};