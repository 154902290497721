<template>
  <div class="vCheckout4">

    <h1 class="text-center">{{ $t('pages.checkOut.headline') }}</h1>
    <steps :steps="[]" />

    <div class="container">
      <div class="row">
        <div class="col-12 col-md-7">
          <div class="hr mx-0"><span>{{ $t('pages.checkOut.yourOverview') }}</span></div>
          <ul class="overviewList">
            <li>
              <span class="title">{{ $t('common.yourStudio') }}:</span>
              <span class="value">{{ studio.name }} </span>
              <a href="#" @click="$store.commit('SET_STEP', {step: 0})">{{  $t('common.edit') }}</a>
            </li>
            <li>
              <span class="title">{{ $t('common.yourPlan') }}:</span>
              <span class="value"> {{ contract.Bezeichnung }} ({{ contract.vertBeitrag | formatCurrency }})</span>
              <a href="#" @click="$store.commit('SET_STEP', {step: 1})">{{  $t('common.edit') }}</a>
            </li>
            <li>
              <span class="title">{{ $t('common.yourAddition') }}:</span>
              <span class="value" v-if="addition">{{ addition.bezeichnung_current_language }} ({{ addition.zusaBeitrag | formatCurrency }})</span>
              <span class="value" v-else>{{ $t('common.noAddition') }}</span>
              <a href="#" v-if="!$store.state.basket.contractCode" @click="$store.commit('SET_STEP', {step: 2})">{{  $t('common.edit') }}</a>
            </li>
          </ul>
          <div class="hr mx-0"><span>{{ $t('pages.checkOut.yourData') }}</span></div>
          <ul class="overviewList">
            <li>
              <span class="title"><strong>{{$store.state.user.fName}} {{$store.state.user.lName}}</strong> </span>
              <span class="subvalue"><i>{{ $t('pages.checkOut.address') }}</i> {{$store.state.user.street}} {{$store.state.user.streetNo}}<br> {{$store.state.user.zipcode}} {{$store.state.user.city}}</span>
              <span class="subvalue"><i>{{ $t('widget.user.birthday') }}</i> {{$store.state.user.birthday}}</span>
              <span class="subvalue"><i>{{ $t('widget.user.phone') }}</i> {{$store.state.user.phone}}</span>
              <span class="subvalue"><i>{{ $t('widget.user.email') }}</i> {{$store.state.user.email}}</span>
              <a href="#" @click="$store.commit('SET_STEP', {step: 4})">{{  $t('common.edit') }}</a>
            </li>
            <li>
              <span class="title">{{ $t('pages.checkOut.yourMembershipStart') }}:</span>
              <span class="value"> {{ $store.state.order.membership.value }}<br class="d-sm-none"> ({{$t('widget.membership.trainingsStart', {date:getTrainingsStart()})}})</span>
              <a href="#" @click="$store.commit('SET_STEP', {step: 5})">{{  $t('common.edit') }}</a>
            </li>
            <li>
              <span class="title">{{ $t('pages.checkOut.paymentInformation') }}:</span>
              <span class="value">{{ paymentMethods[$store.state.order.payment].name }} <img class="icon inline" :src="paymentMethods[$store.state.order.payment].icon" /></span>
              <a href="#" @click="$store.commit('SET_STEP', {step: 6})">{{  $t('common.edit') }}</a>
            </li>
          </ul>
        </div>
        <div class="col">
          <div class="h-100 d-flex flex-column">
            <div class="hr mx-0"><span>{{ $t('common.basket') }}</span></div>

            <div>
            <basket></basket>
            </div>
            <div class="row mt-3">
              <div class="col-8 pr-1">
                <div class="form-group  mb-0">
                  <input class="form-control" type="text" v-model="coupon.value" :class="{filled:coupon.value}" required>
                  <label>{{ $t('pages.checkOut.coupon') }}</label>
                </div>
              </div>
              <div class="col pl-1">
                <button class="btn btn-default btn-block" :disabled="!coupon.value" @click="sendCoupon">
                  {{ $t('pages.checkOut.couponRedeem') }}
                </button>
              </div>
            </div>
            <div v-if="coupon.status < 0" class="text-danger">
              {{  $t('pages.checkOut.couponDecline', {coupon: coupon.value}) }}
              <span v-if="coupon.error">{{ $t('coupon.error.' + coupon.error) }}</span>

            </div>
            <div v-if="$store.state.basket.coupon" class="text-success">
                {{  $t('pages.checkOut.couponAccept', {coupon: coupon.value}) }}
                <div v-if="$store.state.basket.coupontext">
                  {{ $store.state.basket.coupontext }}
                </div>
            </div>

            <label class="checkbox mt-3">
              <input type="checkbox" v-model="check1" value="1"><i></i>
              <i18n path="pages.checkOut.checkbox247" tag="span" v-if="studio.isTwentyFourSeven || contract.VertragSubTyp == 'Premium'">
                <template v-slot:url_dataprotection>
                  <a :href="$root.config.urlPrivacy" target="_blank">{{ $t('pages.selectData.dataProtection') }}</a>
                </template>
                <template v-slot:url_agb>
                  <a :href="$root.config.urlAGB" target="_blank">{{ $t('pages.checkOut.checkboxURLAGB') }}</a>
                </template>
                <template v-slot:houseRules>
                  <a :href="$root.config.urlHouseRules" target="_blank">{{ $t('pages.checkOut.houseRules') }}</a>
                </template>
                <template v-slot:unmannedPeriods>
                  <a :href="$root.config.urlTwentyFourSeven" target="_blank">{{ $t('pages.checkOut.checkboxURLUnmannedPeriods') }}</a>
                </template>
              </i18n>
              <i18n path="pages.checkOut.checkbox" tag="span" v-else>
                <template v-slot:url_dataprotection>
                  <a :href="$root.config.urlPrivacy" target="_blank">{{ $t('pages.selectData.dataProtection') }}</a>
                </template>
                <template v-slot:url_agb>
                  <a :href="$root.config.urlAGB" target="_blank">{{ $t('pages.checkOut.checkboxURLAGB') }}</a>
                </template>
                <template v-slot:houseRules>
                  <a :href="$root.config.urlHouseRules" target="_blank">{{ $t('pages.checkOut.houseRules') }}</a>
                </template>
              </i18n>
            </label>
            <label class="checkbox" v-if="contract.vertkuendigungstext_current_language">
              <input type="checkbox"  v-model="check2" value="F"><i></i>
              <span>{{ contract.vertkuendigungstext_current_language }}</span>
            </label>
            <label class="checkbox" v-if="contract.vertaktionstext_current_language">
              <input type="checkbox"  v-model="check3" value="F"><i></i>
              <span>{{ contract.vertaktionstext_current_language }}</span>
            </label>
            <label class="checkbox" v-if="showMinorSunAgree()">
              <input type="checkbox"  v-model="check4" value="F"><i></i>
              <span>{{ $t('widget.miniors.sunflatAgree') }}</span>
            </label>
            <label class="checkbox" v-if="contract.servicetext_current_language">
              <input type="checkbox"  v-model="check5" value="F"><i></i>
              <span>{{ contract.servicetext_current_language }}</span>
            </label>


            <div class="row my-3">
              <div class="col">
                <button class="btn btn-primary btn-block btn-xxl" @click="pay()" :disabled="check()"><i v-if="sending" class="fas fa-sync fa-spin"></i>
                  <span v-if="!$store.state.basket.contractCode">{{ $t('pages.checkOut.orderForFee') }}</span>
                  <span v-else>{{ $t('pages.checkOut.orderForFree') }}</span>
                </button>
              </div>
            </div>
            <form ref="payment" v-if="paymentData" :action="paymentData.url" method="post">
              <div class="form-group" v-for="(value, key) in paymentData.data" :key="value">
                <input type="hidden"  v-model="paymentData.data[key]" :name="key" >
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Steps from "@/components/StepsPart2";
import { mapState } from "vuex";

import Basket from "@/components/Basket";
import moment from "moment";

export default {
  name: "Checkout4",
  components: {Basket, Steps},
  mounted() {
    this.$store.commit("SET_STEP", {step:7});
  },
  methods: {
    isMinior(){
      if(!this.$store.state.user.birthday) return false;

      let today = moment();
      let birthday = moment(this.$store.state.user.birthday, "DD.MM.YYYY");
      let years = moment.duration(today.diff(birthday)).asYears();

      //alert(years);

      return years < 18;
    },
    showMinorSunAgree(){
      return (this.$store.state.addition && this.$store.state.addition.Typ == 'Sparpaket' &&  this.isMinior());
    },
    pay(){
      this.sending = true;
      this.axios.post("api/shop/order/payment",{
          user: this.$store.state.user,
          contract: this.$store.state.contract,
          studio: this.$store.state.studio,
          order: this.$store.state.order,
          addition: this.$store.state.addition,
      },
      {params: {lang: this.$i18n.locale}}
      ).then(res =>{
        this.paymentData = res.data;

        if(this.paymentData.url == undefined && res.data.payment == true){
          this.$router.push('thankyou');
          this.sending = false;
        }
        else{
          setTimeout(()=>{
            this.$refs.payment.submit();
          },100);
        }
      }).catch(error =>{
        this.sending = false;
        alert('Ein unbekannter Fehler ist aufgetreten.');
      });
    },
    sendCoupon(){
      this.axios.post('api/shop/order/coupon', {coupon: this.coupon}, {params: {lang: this.$i18n.locale}}).then(res => {

        if(res.data.error){
          this.coupon.status = -1;
          this.coupon.error = res.data.error;
          return null;
        }

        this.$store.commit('SET_BACKOFFICE', {order: res.data.order, basket: res.data.basket});
        if (typeof window.ga !== 'undefined'){window.ga('send', 'event', 'Bestellung', 'Gutschein hinzugefügt');}
        this.coupon.status = 1;
      }).catch(error => {
        this.coupon.status = -1;
        console.log(error);
      });
    },
    check(){
      if(this.sending) return true;
      if(!this.check1) return true;

      if(this.contract.vertkuendigungstext_current_language && !this.check2) return true;

      if(this.contract.vertaktionstext_current_language && !this.check3) return true;

      if(this.showMinorSunAgree() && !this.check4) return true;

      if(this.contract.servicetext_current_language && !this.check5) return true;

      return false;
    },
    getTrainingsStart(){

      if(this.$store.state.order.membership.format == "DD.MM.YYYY"){
        return this.$store.state.order.accessdate.value;
      }

      if(this.$store.state.order.membership.value > this.$store.state.order.membership.defaultValue){
        return this.$store.state.order.membership.value;
      }

      return this.$store.state.order.accessdate.value;
      /*
      if(this.$store.state.order.membership.value > this.$store.state.order.membership.defaultValue){
        return this.$store.state.order.membership.value;
      }
      return this.$t('widget.membership.rightAway');*/
    }
  },
  watch:{
      'coupon.value'(value){
          this.coupon.status = 0;
      }
  },
  computed: mapState(["currentStep", "studio", "contract", "addition"]),
  data(){
    return{
      paymentMethods: {
        "paypal": {active: true,name: "PayPal", id: "paypal", icon: "/shop/img/payments/paypal-logo.svg"},
        "visa":  {active: true,name: "Visa", id: "visa", icon: "/shop/img/payments/visa-logo.svg"},
        "mastercard":   {active: true,name: "Mastercard", id: "mastercard", icon: "/shop/img/payments/mastercard-logo.svg"},
        "postfinancecard":  {active: true,name: "Postfinancecard", id: "postfinancecard", icon: "/shop/img/payments/post-finance-logo.svg"},
        "postfinance":  {active: true, name: "Postfinance", id: "postfinance", icon: "/shop/img/payments/e-finance-logo.svg"},
        "ec":  {active: false, name: "Studio EC-Card", id: "ec", icon: "/shop/img/payments/ec-logo.svg"},
        "bar": {active: false, name: "Studio Bar-Zahlung", id: "bar", icon: "/shop/img/payments/bar-logo.svg"},
        "invoice": {active: false, name: "Rechnung", id: "invoice", icon: "/shop/img/payments/rechnung-logo.svg"},
      },
      paymentData: null,
      check1: false,
      check2: false,
      check3: false,
      check4: false,
      check5: false,
      checkHouseRules: false,
      coupon: {
        value: "",
        status: 0,
        error: null
      },
      sending: false,
    }
  }
}
</script>

<style scoped>

</style>