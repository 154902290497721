export default {
    wohlen: "Kurse ab 5. Juli verfügbar.",
    common: {
        dateFormat: "DD.MM.YYYY",
        currency: "CHF {value}",
        choose: "Wählen",
        next: "Weiter",
        previous: "Zurück",
        bestChoice: "Beste Wahl",
        and: "und",
        or: "oder",
        edit: "Bearbeiten",
        basket: "Warenkorb",
        yourStudio: "Dein Club",
        yourPlan: "Deine Mitgliedschaft",
        yourAddition: "Dein Zusatz",
        noAddition: "Kein Zusatz gewählt",
        days: "Tag | 1 Tag | {count} Tage",
        months: "Monat | 1 Monat | {count} Monate",
        checkout: "Zur Kasse",
        abort: "Abbrechen",
        opening: "Eröffnung {date}",
        employerLogin: "Mitarbeiter Login",
        username: "Benutzername",
        password: "Passwort",
    },
    widget: {
        miniors: {
            headline: "Minderjährige",
            minors1: "Du bist noch nicht volljährig?",
            minors2: "Lass dieses {Formular} von deinem Erziehungsberechtigten ausfüllen.",
            form: "Formular",
            formUrl: "https://puregym.swiss/wp-content/uploads/2021/12/211217_U18_Abo_A4_DE.pdf",
            minors3: "Bring deinen Ausweis und Erziehungsberichtigten zum ersten Training mit und los geht’s!",
            sunflat: "Nutzung des Solariums für Personen unter 18 Jahren nicht erlaubt.",
            sunflatAgree: "Ich stimme zu, dass ich das Solarium aufgrund meiner Minderjährigkeit nicht nutzen darf.",
        },
        studio: {
            searchPlaceholder: "Wo möchtest du trainieren?",
            clubList: "Clubliste",
            mapView: "Kartenansicht",
        },
        basket: {
            totalAmountFull: "Gesamtbetrag",
            totalAmount: "Gesamt",
        },
        user: {
            genderFemale: "Frau",
            genderMale: "Mann",
            genderDivers: "Divers",
            firstname: "Vorname",
            lastname: "Nachname",
            email: "E-Mail",
            phone: "Telefon",
            birthday: "Geburtstag",
        },
        address: {
            street: "Straße",
            streetno: "Hausnummer",
            zipcode: "PLZ",
            city: "Stadt",
            country: "Land",
        },
        membership: {
            membership: "Mitgliedschaft",
            startOfMembership: "Beginn deiner Mitgliedschaft",
            trainingsStart: "Trainingsstart: {date}",
            rightAway: "sofort",
        },
    },
    footer: {
        backToHomepage: "Zurück zur Webseite",
        imprint: "Impressum",
        dataProtection: "Datenschutz",
        paymentOptions: "Zahlungsart",
    },
    steps: {
        step1_1: "Club wählen",
        step1_2: "Mitgliedschaft wählen",
        step1_3: "Zusätze",
        step1_4: "Übersicht",
        step2_1: "Daten",
        step2_2: "Adresse",
        step2_3: "Bezahlung",
        step2_4: "Bestätigung",
    },
    pages: {
        failed:{
            headline: "Zahlung fehlgeschlagen",
            intro: "Leider ist deine Zahlung fehlgeschlagen oder du hast Sie abgebrochen.",
            here: "Hier",
            newOrder: "{url} kannst du eine neue Bestellung starten."
        },
        selectClub: {
            headline: "Wähle deinen Club",
        },
        selectContract: {
            headline: "Wähle deine Mitgliedschaft",
            choosePlan: "Wähle deine Mitgliedschaft",
            chooseRuntime: "Wähle deine Laufzeit",
            basisPlan: "Basis Angebot",
            aktionPlan: "Sonderangebot",
            premiumPlan: "Bestes Angebot",
            friendCode: "FRIEND-Code",
            friendCode2: "Hast du einen FRIEND-Code? <strong><u>Dann klicke hier.</u></strong>",
            friendCodeDecline: "Der FRIEND-Code {coupon} wurde abgelehnt.",
            freeTrainer: "Gratis Training vom 15. - 20. Juni, 2022",
            planPoints: [
                "PureGym App inkl. Live Auslastung",
                "Fitness On-Demand",
                "Zugang zu allen Clubs",
                "Ein Freund trainiert gratis am WE",
                "Kündigungsfrist",
                "Mitgliedschaft pausieren",
                "Einschreibegebühr",
                "Kündigungsfrist",
                "Jährliche Wartungsgebühr"
            ],
            tooltips: {
                weekendFriend:
                    'Premium Mitglieder haben die Möglichkeit am Wochenende (Samstag und Sonntag) ohne zusätzliche Gebühr eine zusätzliche Person ("Friend") zur gemeinsamen Mitbenutzung der Einrichtungen mitzubringen. Siehe AGB p.6 für weitere Details.',
                freezeMembership:
                    "Ein begründeten ´Time-Stopp´ bis zu 9 Monate (zum Beispiel Militärpflicht, Krankheit, Beruflicher Auslandsaufenthalt) und unbegründeten ´Time-Stopp´ bis zu 3 Monate pro Jahr erlaubt. Siehe AGB p.13 für weitere Details.",
                serviceFee: "<strong>Was ist die jährliche Wartungsgebühr</strong><br>" +
                    "Durch die Erhebung einer jährlichen Wartungsgebühr sind wir in der Lage: " +
                    "<ul style='padding-left: 1rem; font-size: 0.9rem;'>" +
                    "<li>fortlaufend Geld in neue Geräte in deinem Fitnessstudio zu investieren.</li>" +
                    "<li>die Qualität der Einrichtungen zu erhalten und zu verbessern, was unseren Mitgliedern zugute kommt.</li>" +
                    "</ul>" +
                    "<p>Das gesamte Geld, das wir durch die Wartungsgebühren in diesem Fitnessstudio einnehmen, wird in das Fitnessstudio investiert.</p>" +
                    "<p>Bei monatlichen Verträgen wird die jährliche Wartungsgebühr zu deiner vierten monatlichen Zahlung hinzugefügt. Bei Jahresverträgen wird die jährliche Wartungsgebühr mit deinem Mitgliedsbeitrag fällig.</p>",
            },
        },
        selectAddition: {
            headline: "Wähle deine Zusätze",
            unselectAddition: "Keine Zusätze wählen",
        },
        overview: {
            headline: "Deine Übersicht",
            yourData: "Deine Angaben",
            voucherHintHeadline:  "Du hast einen Gutschein?",
            voucherHint:  "Diesen kannst du im nächsten Schritt einlösen.",
        },
        selectData: {
            headline: "Kasse",
            dataProtection: "Datenschutz",
            checkboxDataPolice: "Ich stimme dem {DP_URL} von PureGym AG zu.",
            checkboxNewsletter:
                "Ja, ich möchte Werbeinformationen und Informationen zu anderen Produkten von PureGym AG und seinen Partnern erhalten.",
            footerActionText: "Bitte ausfüllen",
            yourData: "Deine Daten",
        },
        selectAddress: {
            headline: "Kasse",
            yourAddress: "Deine Adresse",
            yourMembership: "Mitgliedschaft",
            howDoYouKnow: "Woher kennst du uns?",
            footerName: "Hallo {fname}",
            footerActionText: "Bitte deine Adresse eingeben",
        },
        selectPayment: {
            headline: "Kasse",
            yourPaymentOptions: "Deine Zahlungsmethoden",
            //healthInsuranceRefund: "Krankenkassen Rückerstattung",
            healthInsuranceRefund: "Spar dich gesund",
            checkboxHealthInsuranceRefund: "<p>Ja, ich möchte vom Partnerangebot der SWICA, bis zu 25% Prämienrabatt und bis zu CHF 600 Rückerstattung*, profitieren und kontaktiert werden. Ich bin damit einverstanden, dass meine Personendaten zu Werbezwecken und zur Kontaktaufnahme von PureGym AG an SWICA Krankenversicherung AG weitergegeben werden.</p>" +
                "<p><strong>Für die Kontaktaufnahme zu Werbezwecken unseres Partners SWICA ist die Datenweitergabe auf das Geschlecht, die Namens-, Adress- und Kontaktdaten sowie das Geburtsdatum beschränkt.</strong> Alle Informationen zu den Gesundheitsfragen werden nicht weitergegeben. Das Mitglied kann SWICA die Verwendung der Daten jederzeit unter <a target='_blank' href='https://www.swica.ch/datenschutz'>www.swica.ch/datenschutz</a> wiederrufen. *Detaillierte Bedingungen auf der Partnerseite <a target='_blank' href='https://www.swica.ch/puregym'>www.swica.ch/puregym</a></p>",
            checkboxUrlText: "hier",
            footerName: "Hallo {fname}",
            footerActionText: "Nur noch ein Schritt",
        },
        checkOut: {
            headline: "Kasse",
            yourOverview: "Dein Überblick",
            yourData: "Deine Daten",
            address: "Adresse",
            yourMembershipStart: "Dein Mitgliedschaftsbeginn",
            paymentInformation: "Zahlungsinformationen",
            couponRedeem: "Einlösen",
            coupon: "Gutscheincode",
            couponAccept: "Der Gutscheincode {coupon} wurde aktiviert.",
            couponDecline: "Der Gutscheincode {coupon} wurde abgelehnt.",
            orderForFee: "Kostenpflichtig Bestellen",
            orderForFree: "Jetzt kostenlos Bestellen",
            checkboxAGB: "Ich stimme dem {url_agb} und den {url_dataprotection} von PureGym AG zu.",
            checkbox: "Ich bin mit den {url_agb} und dem {url_dataprotection} der PureGym AG einverstanden und akzeptiere hiermit die {houseRules} der PureGym AG.",
            checkbox247: "Ich erkläre mich mit den {url_agb}, den {url_dataprotection}, der {houseRules} und den {unmannedPeriods} der PureGym AG einverstanden.",
            checkboxURLUnmannedPeriods: "unbetreuten Zeiten",
            checkboxURLAGB: "AGB",
            checkContract:
                "Ich stimme zu, dass der Vertrag sich jeweils um weitere 1 Monat zu den allgemeinen Bedingungen verlängert, wenn er nicht mindestens 10 Werktage vor Ablauf durch schriftliche Kündigung aufgehoben wird.",
            checkContract12:
                "Ich stimme zu, dass der Vertrag sich jeweils um weitere 1 Monat zu den allgemeinen Bedingungen verlängert, wenn er nicht mindestens 10 Werktage vor Ablauf durch schriftliche Kündigung aufgehoben wird.",
            checkHouseRules: "Hiermit akzeptiere ich die {houseRules} von PureGym AG.",
            houseRules: "Hausordnung",
        },
    },
    fields: {
        User: "Benutzername",
        Password: "Passwort",
        fname: "Vorname",
        lname: "Nachname",
        birthday: "Geburtstag",
        phone: "Telefon",
        email: "E-Mail",
        street: "Straße",
        streetNo: "Hausnummer",
        zipcode: "PLZ",
        city: "Stadt",
    },
    validations: {
        messages: {
            alpha: "{_field_} darf nur alphabetische Zeichen enthalten",
            alpha_dash: "{_field_} darf alphanumerische Zeichen sowie Striche und Unterstriche enthalten",
            alpha_num: "{_field_} darf nur alphanumerische Zeichen enthalten",
            alpha_spaces: "{_field_} darf nur alphanumerische Zeichen und Leerzeichen enthalten",
            between: "{_field_} muss zwischen {min} und {max} liegen",
            confirmed: "Die Bestätigung von {_field_} stimmt nicht überein",
            digits: "{_field_} muss numerisch sein und exakt {length} Ziffern enthalten",
            dimensions: "{_field_} muss {width} x {height} Bildpunkte groß sein",
            email: "{_field_} muss eine gültige E-Mail-Adresse sein",
            excluded: "{_field_} muss ein gültiger Wert sein",
            ext: "{_field_} muss eine gültige Datei sein",
            image: "{_field_} muss eine Grafik sein",
            oneOf: "{_field_} muss ein gültiger Wert sein",
            integer: "{_field_} muss eine ganze Zahl sein",
            length: "Die Länge von {_field_} muss {length} sein",
            max: "{_field_} darf nicht länger als {length} Zeichen sein",
            max_value: "{_field_} darf maximal {max} sein",
            mimes: "{_field_} muss einen gültigen Dateityp haben",
            min: "{_field_} muss mindestens {length} Zeichen lang sein",
            min_value: "{_field_} muss mindestens {min} sein",
            numeric: "{_field_} darf nur numerische Zeichen enthalten",
            regex: "Das Format von {_field_} ist ungültig",
            required: "{_field_} ist ein Pflichtfeld",
            required_if: "{_field_} ist ein Pflichtfeld",
            size: "{_field_} muss kleiner als {size}KB sein",
            double: "Das Feld {_field_} muss eine gültige Dezimalzahl sein",
        },
    },
    promo: {
        desktop: "Zahle nur CHF 10 für dein Monatsabo Core, oder CHF 25 für die Premium-Version: Verwende im letzten Schritt im Webshop den Promo-Code <b>PRESENT</b> oder <b>PRESENTPREMIUM.</b>",
        mobile: "Verwende den Promo-Code <b>PRESENT</b> und zahle für Monatsabo Core CHF 10 / <b>PRESENTPREMIUM</b> für Monatsabo Premium CHF 25",
    },
    closeInNovember: "Bis Ende November 2021 geöffnet!",
    BernLorraine: "Bern Lorraine: Ab 25.10.2021 Umbau mit vielen Upgrades. Erfahre hier <a href='/bern-lorraine-upgrade.html'>mehr</a>.",
    coupon: {
        error:{
            invalidCode: "Ungültiger Gutscheincode.",
            invalidStudio: "Der Gutscheincode ist für dieses Fitnessstudio ungültig.",
            invalidFlat: "Der Gutscheincode ist für Zusätze ungültig.",
            invalidContractType: "Der Gutscheincode ist für diese Mitgliedschaft ungültig.",
            invalidContractRuntime: "Der Gutscheincode ist für diese Laufzeit ungültig.",
            invalidFlatType: "Der Gutscheincode ist für diesen Zusatz ungültig.",
        }
    }
};
