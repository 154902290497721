<template>
  <div class="steps">
    <ul>
      <li :class="{active: currentStep == 4, finish: currentStep > 4}">
        <span @click="goto(4)">{{ step1 }}</span>
      </li>
      <li :class="{active: currentStep == 5, finish: currentStep > 5}">
        <span @click="goto(5)">{{ step2 }}</span>
      </li>
      <li :class="{active: currentStep == 6, finish: currentStep > 6}">
        <span @click="goto(6)">{{ step3 }}</span>
      </li>
      <li :class="{active: currentStep == 7}">
        <span>{{ $t('steps.step2_4') }}</span>
      </li>
      <!--<li :class="{active: step.active, finish: step.finish}" v-for="(step, theKey, index) in steps" :key="index">
        <span v-if="step.finish" @click="$store.commit('SET_STEP', {step: step.step})" :id="theKey.toString()">{{ getTitle(step) }}</span>
        <span v-else :id="theKey.toString()">{{ getTitle(step) }}</span>
      </li>-->
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "StepsPart1",
  props: ['steps'],
  computed:{
    ...mapState(["studio", "contract", "addition"]),
    currentStep(){
      return this.$store.state.currentStep;
    },
    step1(){
      return this.$t('steps.step2_1');
    },
    step2(){
      return this.$t('steps.step2_2');
    },
    step3(){
      return this.$t('steps.step2_3');
    },
  },
  watch:{
    currentStep(){

    },
  },
  methods: {
    goto(index){
      if(this.currentStep <= index) return false;

      this.$store.commit("SET_STEP", {step: index});
    },
    getTitle(step){
      if(step.value){
        return step.value;
      }
      return step.name;
    }
  }
}
</script>

<style scoped>

</style>