import $ from "jquery/dist/jquery.min"
import Vue from 'vue'
import axios from 'axios'
//import 'es6-promise/auto'
import VueAxios from 'vue-axios'
import App from './App.vue'

import PerfectScrollbar from 'vue2-perfect-scrollbar'
import * as VueGoogleMaps from 'vue2-google-maps'
import GmapCluster from 'vue2-google-maps/src/components/cluster'
import VueGtm from '@gtm-support/vue2-gtm';

Vue.use(VueGtm, {
  id: 'GTM-5TL2JB', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
  defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
  compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
});

//import { BootstrapVue } from 'bootstrap-vue'
//vue.use(BootstrapVue);

//Import Icons
import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowLeft, faCheck, faTimes} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faArrowLeft);
library.add(faCheck);
library.add(faTimes);


import VueRouter from 'vue-router'
import router from './routes'


import 'moment/locale/de';
import moment from "moment";

import VueMoment from 'vue-moment'
Vue.use(VueMoment);

import datePicker from 'vue-bootstrap-datetimepicker';
Vue.use(datePicker);

Vue.router = router;
Vue.use(VueRouter);

import * as VueWindow from '@hscmap/vue-window'

Vue.use(VueWindow)


Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyB9vtQdZaIRvALNsKpgJ4Mv9RKoHqr5nmY',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
});
Vue.component('GmapCluster', GmapCluster)


Vue.use(VueAxios, axios);
Vue.use(PerfectScrollbar);

import store from './store/index'

Vue.filter("formatCurrency", function (value) {
  return new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(parseFloat(value)).replace(/\D00(?=\D*$)/, '');
});

Vue.filter("formatCurrency4", function (value) {
  return new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(value);
});

Vue.filter("formatCurrency2", function (value) {
  return new Intl.NumberFormat('de-CH', { style: 'decimal', maximumFractionDigits: 2  }).format(parseInt(value)).replace(/\D00(?=\D*$)/, '');
});

Vue.filter("formatCurrency5", function (value) {
  return new Intl.NumberFormat('de-CH', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2  }).format(parseFloat(value));
});

Vue.filter("formatCurrency3", function (value) {
  return new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF'  }).format(parseFloat(value)).replace(/\D00(?=\D*$)/, '');
});


Vue.filter("formatNumber", function (value) {
  return new Intl.NumberFormat('de-DE').format(value);
});

import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import de from 'vee-validate/dist/locale/de.json';
import { configure } from 'vee-validate';

configure({
  // this will be used to generate messages.
  defaultMessage: (field, values) => {
    values._field_ = i18n.t(`fields.${field}`);
    return i18n.t(`validations.messages.${values._rule_}`, values);
  }
});

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

Vue.component('vue-phone-number-input', VuePhoneNumberInput);


axios.defaults.baseURL = "https://tbc.bitdivision.de";
axios.defaults.withCredentials = true;






if(!localStorage.getItem('X-MEN')){
    axios.get('api/shop/order/create').then(res=>{
      localStorage.setItem('X-MEN', res.data.token);
    }).catch(error => {alert("Ein Fehler ist aufgetreten");  location.reload(); });
}

axios.defaults.headers.common['X-MEN'] = localStorage.getItem('X-MEN');


import VTooltip from 'v-tooltip'
Vue.use(VTooltip, {
  defaultTrigger: window.innerWidth > 768 ? 'hover focus click' : 'click'
});


import i18n from "./setup/i18n";

new Vue({
  i18n,
  router,
  beforeCreate() {
    this.$store.dispatch("init");

    if(window.shopconfig.url){
      axios.defaults.baseURL = window.shopconfig.url;
    }

    //alert(axios.defaults.baseURL);
  },
  mounted(){
    if(process.env.NODE_ENV != undefined && process.env.NODE_ENV === "development"){
      this.debug = true;
    }
  },
  data: {
    config: window.shopconfig,
    debug: false,
  },
  store: store,
  render: h => h(App),
}).$mount('#app')
