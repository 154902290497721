export default {
    locale: "de",
    currentStep: 0,
    headers: null,
    studio: null,
    contract: null,
    addition: null,
    recommendations: false,
    user: {
        gender: 'F',
        fName: "",
        lName: "",
        email: "",
        phone: "",
        birthday: "",
        street: "",
        streetNo: "",
        zipcode: "",
        city: "",
    },
    order: {
        payment: null,
        qualitop: null,
        membership:{
            format: "DD.MM.YYYY",
            minDate: new Date(),
            value: new Date(),
            defaultValue: new Date(),
            disabled: true,
        },
        accessdate:{
            format: "DD.MM.YYYY",
            minDate: new Date(),
            value: new Date(),
            defaultValue: new Date(),
            disabled: true,
        }
    },
    basket:{

    }

};