<template>
  <div class="vContracts">

    <div class="container-fluid container-lg">
      <h1 class="text-center">{{ $t('pages.selectContract.headline') }}</h1>
      <steps :steps="[]"/>


      <div class="row" v-if="!isContractCode()">
        <div class="col text-right">
          <a href="#" @click="modal.show()" v-html="$t('pages.selectContract.friendCode2')"></a>
        </div>
      </div>

      <div class="row align-items-center sticky" v-if="!isContractCode()">
        <div class="col d-none d-sm-block">
          <div class="stepLine">
            <span>1</span> {{ $t('pages.selectContract.chooseRuntime') }}
          </div>
        </div>
        <div class="col-sm-6 p-0">
          <ul class="selectRuntime row">
            <li class="col m-1" :class="{selected: selectedDir == dir}" v-for="dir in dirs" v-bind:key="dir.id" @click="selectDir(dir)">
              <div class="row h-100 align-items-center">
                <div class="col">
                  <!--<span class="subtitle">{{dir.subtitle_current_language}}</span>-->
                  <span class="title">{{ dir.subtitle_current_language }}</span>
                  <!--<span class="title">{{dir.Bezeichnung}}</span>-->
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="row" v-if="!isContractCode()">
        <div class="col d-none d-sm-block">
        </div>
        <div class="col-sm-6 p-sm-0">
          <div class="hr mx-0"><span>{{ $t('common.and') }}</span></div>
        </div>
      </div>





      <div v-if="contracts.length && !isContractCode()" class="contracts">
        <div class="row">
          <div class="col-sm-6 px-0 d-none d-sm-block">
            <div class="stepLine mx-3">
              <span>2</span> {{ $t('pages.selectContract.choosePlan') }}
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row">
              <div :class="{selected: isCore(), hover: coreHover, aktion: getStrikeCorePrice() > 0}"
                   @click="selectCore" @mouseenter="coreHover = true" @mouseleave="coreHover = false"
                   class="col coreSelect p-0 text-center" :data-aktion="$t('pages.selectContract.aktionPlan')">
                <div class="contract">
                  <span class="title">Core</span>
                  <span class="subtitle">{{ $t('pages.selectContract.basisPlan') }}</span>
                </div>
                <div class="price">
                  <span class="xprice " style="text-decoration: line-through; display: block;" v-if="getStrikeCorePrice() > 0">{{ getStrikeCorePrice() | formatCurrency }}</span>
                  <span :class="{'text-primary': is(contracts[0],'Core', 1)}">{{ getCorePrice() | formatCurrency }}</span>
                </div>
              </div>
              <div :class="{selected: isPremium(), hover: premiumHover,aktion: is(contracts[1], 'Premium', 1)}"
                   @click="selectPremium" @mouseenter="premiumHover = true" @mouseleave="premiumHover = false"
                   class="col premiumSelect p-0 text-center" :data-aktion="$t('pages.selectContract.aktionPlan')"
                   :data-premium="$t('common.bestChoice')">
                <div class="contract">
                  <span class="title">Premium</span>
                  <span class="subtitle">{{ $t('pages.selectContract.premiumPlan') }}</span>
                </div>
                <div class="price">
                  <span class="xprice" style="text-decoration: line-through; display: block;" v-if="getStrikePremiumPrice() > 1">{{ getStrikePremiumPrice() | formatCurrency }}</span>
                  <span :class="{'text-primary': is(contracts[0],'Core', 1)}">{{ getPremiumPrice() | formatCurrency }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bullets">
          <template v-for="(bullet, index) in bullets" >
            <div class="row"  :key="index" :id="index" v-if="bullet.show">
              <div class="col-12 col-sm-6 p question">
                {{ $t(bullet.q) }} <i class="fas fa-question-circle" v-if="bullet.tooltip"
                                      v-tooltip="$t(bullet.tooltip)"></i>
              </div>
              <div class="col col-sm-6">
                <div class="row h-100">
                  <div :class="{selected: isCore(), hover: coreHover, last: (index == bullets.length - 1)}"
                       @mouseenter="coreHover = true" @mouseleave="coreHover = false" @click="selectCore"
                       class="col coreBullets p px-0 text-center">
                    <span v-if="bullet.core.text">{{ bullet.core.value }}</span>
                    <span v-else-if="!bullet.core.icon">
                      <span class="xprice" style="text-decoration: line-through;" v-if="bullet.core.strikePrice">{{ bullet.core.strikePrice | formatCurrency }}</span>
                      {{ bullet.core.value | formatCurrency }}
                    </span>
                    <font-awesome-icon v-else :icon="bullet.core.value"/>
                  </div>
                  <div :class="{selected: isPremium(), hover: premiumHover, last: index == bullets.length - 1}"
                       @mouseenter="premiumHover = true" @mouseleave="premiumHover = false" @click="selectPremium"
                       class="col premiumBullets p px-0 text-center">
                    <span v-if="bullet.premium.text">{{ bullet.premium.value }}</span>
                    <span v-else-if="!bullet.premium.icon">
                      <span class="xprice" style="text-decoration: line-through;"  v-if="bullet.premium.strikePrice">{{ bullet.premium.strikePrice | formatCurrency }}</span>
                      {{ bullet.premium.value | formatCurrency }}
                    </span>
                    <font-awesome-icon v-else :icon="bullet.premium.value"/>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>

      <div v-if="isContractCode()" class="contracts mt-5">
        <div class="row">
          <div class="col-sm-6 px-0 d-none d-sm-block">
            <div class="stepLine mx-3">
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row">
              <div :class="{selected: true, hover: coreHover, aktion: true}"
                   @click="selectCore" @mouseenter="coreHover = true" @mouseleave="coreHover = false"
                   class="col coreSelect p-0 text-center" :data-aktion="$t('pages.selectContract.aktionPlan')">
                <div class="contract">
                  <span class="title">{{ contract.Bezeichnung }}</span>
                  <span class="subtitle">Friends4Free</span>
                </div>
                <div class="price">
                  <span :class="{'text-primary': is(contracts[0],'Core', 1)}">{{
                      footerHeadline()
                    }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bullets">
          <div class="row">
            <div class="col-12 col-sm-6 p question">
              {{ $t(bullets[0].q) }} <i class="fas fa-question-circle" v-if="bullets[0].tooltip"
                                    v-tooltip="$t(bullets[0].tooltip)"></i>
            </div>
            <div class="col col-sm-6">
              <div class="row h-100">
                <div class="col coreBullets p px-0 text-center selected">
                  <font-awesome-icon icon="check"/>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-6 p question">
              {{ $t(bullets[1].q) }} <i class="fas fa-question-circle" v-if="bullets[1].tooltip"
                                        v-tooltip="$t(bullets[1].tooltip)"></i>
            </div>
            <div class="col col-sm-6">
              <div class="row h-100">
                <div class="col coreBullets p px-0 text-center selected">
                  <font-awesome-icon icon="check"/>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-6 p question">
              {{ $t('pages.selectContract.freeTrainer') }}
            </div>
            <div class="col col-sm-6">
              <div class="row h-100">
                <div class="col coreBullets p px-0 text-center selected">
                  <font-awesome-icon icon="check"/>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-6 p question">
              {{ $t(bullets[6].q) }}
            </div>
            <div class="col col-sm-6">
              <div class="row h-100">
                <div class="col coreBullets p px-0 text-center selected last">
                  {{ 0 | formatCurrency }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <footer-link-bar></footer-link-bar>
    </div>

    <div class="modal fade" ref="modalContractCode" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <!--<div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{$t('pages.selectContract.friendCode')}}</h5>
            <button type="button" class="btn-close" @click="modal.hide()" aria-label="Close"></button>
          </div>-->
          <div class="modal-body">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <ValidationProvider name="contractCode" rules="required" v-slot="v">
                    <input class="form-control" type="text"  v-model="contractcode.value" :class="{filled:contractcode.value}" maxlength="50" required />
                    <label>{{ $t('pages.selectContract.friendCode') }}</label>
                    <span class="error" v-if="v.errors">{{ v.errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div v-if="contractcode.status < 0" class="text-danger">{{  $t('pages.selectContract.friendCodeDecline', {coupon: contractcode.value}) }}</div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="modal.hide()">{{ $t('common.abort') }}</button>
            <button type="button" class="btn btn-primary" :disabled="!contractcode.value" @click="setContractCode()">{{ $t('pages.checkOut.couponRedeem') }}</button>
          </div>
        </div>
      </div>
    </div>

    <Footer @clickNext="onNextStep" :button-enabled="footerButton()" :headline="footerHeadline()"
            :subheadline="footerSubHeadline()"></Footer>
  </div>
</template>

<script>
import {mapState} from "vuex";
import Footer from "@/components/Footer";
import FooterLinkBar from "@/components/FooterLinkBar";
import Steps from "@/components/StepsPart1";
import {Modal} from 'bootstrap';

export default {
  name: "Contracts",
  components: {Footer, FooterLinkBar, Steps},
  data() {
    return {
      modal: null,
      contractcode: {
        value: "",
        status: 0,
      },
      prices: {
        "108": {"core": 45, "premium": 69},
        "102": {"core": 49, "premium": 69},
        "19": {"core": 45, "premium": 69},
        "84": {"core": 55, "premium": 69},
        "89": {"core": 39, "premium": 69},
        "125": {"core": 55, "premium": 69},
        "101": {"core": 55, "premium": 69},
        "103": {"core": 39, "premium": 69},
        "85": {"core": 45, "premium": 69},
        "86": {"core": 39, "premium": 69},
        "80": {"core": 55, "premium": 69},
        "110": {"core": 45, "premium": 69},
        "99": {"core": 49, "premium": 69},
        "114": {"core": 39, "premium": 69},
        "83": {"core": 39, "premium": 69},
        "32": {"core": 49, "premium": 69},
        "121": {"core": 59, "premium": 69},
        "120": {"core": 49, "premium": 69},
        "97": {"core": 39, "premium": 69},
        "124": {"core": 55, "premium": 69},
        "90": {"core": 45, "premium": 69},
        "104": {"core": 59, "premium": 69},
        "122": {"core": 49, "premium": 69},
        "78": {"core": 45, "premium": 69},
        "117": {"core": 29, "premium": 69},
        "52": {"core": 49, "premium": 69},
        "94": {"core": 39, "premium": 69},
        "115": {"core": 29, "premium": 69},
        "109": {"core": 39, "premium": 69},
        "116": {"core": 39, "premium": 69},
        "16": {"core": 39, "premium": 69},
        "113": {"core": 49, "premium": 69},
        "119": {"core": 45, "premium": 69},
        "60": {"core": 39, "premium": 69},
        "82": {"core": 45, "premium": 69},
        "126": {"core": 39, "premium": 69},
        "87": {"core": 45, "premium": 69},
        "81": {"core": 55, "premium": 69},
        "100": {"core": 39, "premium": 69},
        "88": {"core": 39, "premium": 69},
        "106": {"core": 45, "premium": 69}
      },
      coreHover: false,
      premiumHover: false,
      dirs: [],
      selectedDir: null,
      contracts: [],
      additions: [],
      bullets: [
        {
          show: true,
          q: "pages.selectContract.planPoints.0",
          core: {text: false, icon: true, value: "check"},
          premium: {text: false, icon: true, value: "check"}
        },
        {
          show: true,
          q: "pages.selectContract.planPoints.1",
          core: {text: false, icon: true, value: "check"},
          premium: {text: false, icon: true, value: "check"}
        },
        {
          show: true,
          q: "pages.selectContract.planPoints.2",
          core: {text: false, icon: true, value: "times"},
          premium: {text: false, icon: true, value: "check"}
        },
        {
          show: true,
          q: "pages.selectContract.planPoints.3",
          core: {text: false, icon: true, value: "times"},
          premium: {text: false, icon: true, value: "check"},
          tooltip: "pages.selectContract.tooltips.weekendFriend"
        },
        {
          show: true,
          q: "pages.selectContract.planPoints.4",
          core: {text: false, icon: true, value: "times"},
          premium: {text: false, icon: true, value: "check"}
        },
        {
          show: true,
          q: "pages.selectContract.planPoints.5",
          core: {text: false, icon: false, value: 25},
          premium: {text: false, icon: true, value: "check"},
          tooltip: "pages.selectContract.tooltips.freezeMembership"
        },
        {
          show: true,
          q: "pages.selectContract.planPoints.8",
          core: {text: false, icon: true, value: "times"},
          premium: {text: false, icon: true, value: "check"},
          tooltip: "pages.selectContract.tooltips.serviceFee"
        },
        {
          show: true,
          q: "pages.selectContract.planPoints.6",
          core: {text: false, icon: true, value: "times", strikePrice: null},
          premium: {text: false, icon: true, value: "check", strikePrice: null}
        },

      ]
    }
  },
  mounted() {
    this.getDirs();
    this.$store.commit("SET_STEP", {step: 1});
    this.modal = new Modal(this.$refs.modalContractCode);
  },
  computed: {
    ...mapState(['studio', 'contract']),
  },
  watch: {
    '$i18n.locale': function (newVal, oldVal) {
      this.getDirs();
    },
    studio() {
      this.getDirs();
    },
    selectedDir() {
      this.getContracts();
    },
    contracts() {
      if (!this.contracts.length) {
        this.bullets[7].core = {icon: true, value: "times"};
        return;
      }

      if (this.contracts[0].vertLaufzeit == 1) {
        this.bullets[4].q = "pages.selectContract.planPoints.7";
        this.bullets[4].core = {icon: true, value: "check"};
        this.bullets[4].core = {text: true, icon: false, value: this.$tc("common.days", 10)};
        this.bullets[4].premium = {text: true, icon: false, value: this.$tc("common.days", 10)};
      } else {
        this.bullets[4].core = {icon: true, value: "times"};
        this.bullets[4].core = {text: true, icon: false, value: this.$tc("common.months", 1)};
        this.bullets[4].premium = {text: true, icon: false, value: this.$tc("common.days", 1)};
      }
      this.bullets[7].core = {icon: false, value: this.contracts[0].StartupBetrag, strikePrice: this.contracts[0].StartupBetrag == 0 ? 20 : null};
      this.bullets[5].premium = {icon: false, value: 0};
      this.bullets[7].premium = {icon: false, value: 0};


      if(!this.contracts[0].ServiceBetrag && !this.contracts[1].ServiceBetrag ){
        this.bullets[6].show = false;
      }

      if(!this.contracts[0].ServiceBetrag){
        this.contracts[0].ServiceBetrag = 0;
      }

      if(!this.contracts[1].ServiceBetrag){
        this.contracts[1].ServiceBetrag = 0;
      }

      this.bullets[6].core = {icon: false, value: this.contracts[0].ServiceBetrag};
      this.bullets[6].premium = {icon: false, value: this.contracts[1].ServiceBetrag};

    }
  },
  methods: {
    isContractCode(){
        if(!this.$store.state.basket.contractCode){
          return false;
        }

        if(this.$store.state.basket.contractCode.contract_id != this.$store.state.contract.id){
          return false;
        }

        return true;
    },
    setContractCode() {
      this.axios.post('api/shop/order/contractcode', {contractCode: this.contractcode.value}, {params: {lang: this.$i18n.locale}}).then(res => {
        this.$store.commit('SET_BACKOFFICE', {order: res.data.order, basket: res.data.basket});

        this.$store.dispatch("setContract", {contract: res.data.contract});
        this.$store.dispatch("setAddition", {addition: res.data.addition});
        this.$emit('contractSelected', res.data.contract);
        //if (typeof window.ga !== 'undefined'){window.ga('send', 'event', 'Bestellung', 'Gutschein hinzugefügt');}
        this.contractcode.status = 1;
        this.modal.hide();
        //this.$store.commit('SET_STEP', {step: 3});
      }).catch(error => {
        this.contractcode.status = -1;
      });
    },
    footerButton() {
      return (this.contract !== undefined && this.contract) ? true : false;
    },
    footerHeadline() {
      if (!this.footerButton()) return null;

      let value = parseInt(this.contract.vertBeitrag);
      if (parseInt(this.contract.StartupBetrag)) {
        value += parseInt(this.contract.StartupBetrag);
      }

      return "CHF " + parseInt(value).toString();
    },
    footerSubHeadline() {
      if (!this.footerButton()) return null;

      return this.contract.Bezeichnung
    },
    onNextStep() {
      if(this.isContractCode()) {
        this.$store.commit('SET_STEP', {step: 3});
      }
      else{
        this.$store.commit('SET_STEP', {step: 2});
      }

      if (typeof window.ga !== 'undefined') {
        window.ga('send', 'event', 'Bestellung', 'Vertrag gewählt', this.$store.state.contract.vertName);
      }
    },
    selectContract(contract) {
      console.log(window);
      //this.$store.commit("SET_CONTRACT", {contract: contract});
      this.$store.dispatch("setContract", {contract: contract});
      this.$emit('contractSelected', contract);
    },
    is($contract, type, length) {

      if (!$contract) {
        return false;
      }

      if (!this.contracts.length) {
        return false;
      }

      if ($contract.VertragSubTyp != type) {
        return false;
      }

      if ($contract.vertLaufzeit != length) {
        return false;
      }

      return false;
      //return true;
    },
    isCore() {
      if (!this.contracts.length) {
        return false;
      }

      if (this.contract == this.contracts[0]) {
        return true;
      }

      return false;
    },
    isPremium() {
      if (!this.contracts.length) {
        return false;
      }

      if (this.contract == this.contracts[1]) {
        return true;
      }

      return false;
    },
    selectCore() {
      this.selectContract(this.contracts[0]);
      //console.log(this.contracts[0]);
      //if (typeof window.ga !== 'undefined'){window.ga('send', 'event', 'Bestellung', 'Vertrag gewählt', this.contracts[0].vertName);}
    },
    selectPremium() {
      this.selectContract(this.contracts[1]);
      //if (typeof window.ga !== 'undefined'){window.ga('send', 'event', 'Bestellung', 'Vertrag gewählt', this.contracts[1].vertName);}
    },
    getCorePrice() {
      return this.contracts[0].vertBeitrag;
    },
    getStrikeCorePrice() {
      return this.contracts[0].TestAktion;
    },
    getPremiumPrice() {
      return this.contracts[1].vertBeitrag;
    },
    getStrikePremiumPrice() {
      return this.contracts[1].TestAktion;
    },
    getDirs() {
      let url = "/api/shop/studio/" + this.studio.id + "/dirs";
      //this.dirs = this.contracts = this.additions = [];

      this.selectedDir = null;
      this.$store.commit("SET_CONTRACT", {contract: null});
      this.$store.commit("SET_ADDITION", {addition: null});

      this.axios.get(url, {params: {lang: this.$i18n.locale}}).then(res => {
        this.dirs = res.data.dirs;

        if (!this.selectedDir) {
          this.selectedDir = this.dirs[0];
        }

      }).catch(error => {
        alert("Ein Fehler ist aufgetreten");
      });
    },
    selectDir(dir) {
      this.selectedDir = dir;
    },
    getContracts() {
      let url = "/api/shop/studio/" + this.studio.id + "/dir/" + this.selectedDir.id + "/contracts";
      this.contracts = this.additions = [];
      this.$store.commit("SET_CONTRACT", {contract: null});
      this.$store.commit("SET_ADDITION", {addition: null});

      this.axios.get(url, {params: {lang: this.$i18n.locale}}).then(res => {
        this.contracts = res.data.contracts;

        if (!this.contract) {
          //this.contract = this.contracts[1];
          this.$store.dispatch("setContract", {contract: this.contracts[1]});
        }

      }).catch(error => {
        alert("Ein Fehler ist aufgetreten");
      });
    },
  }
}
</script>

<style scoped>

</style>