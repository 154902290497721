export default {
    wohlen: "Courses available from 5 July.",
    common: {
        dateFormat: "DD.MM.YYYY",
        currency: "CHF {value}",
        choose: "Choose",
        next: "Continue",
        previous: "Back",
        bestChoice: "Best choice",
        and: "and",
        or: "or",
        edit: "Change",
        basket: "Basket",
        yourStudio: "Your club",
        yourPlan: "Your membership",
        yourAddition: "Your extras",
        noAddition: "Continue without extras",
        days: "Day | 1 day | {count} days",
        months: "Month | 1 month | {count} months",
        checkout: "Checkout",
        abort: "Cancel",
        opening: "Opening {date}",
        employerLogin: "Employee Login",
        username: "username",
        password: "password",
    },
    widget: {
        miniors: {
            headline: "Minors",
            minors1: "You are not yet of legal age?",
            minors2: "Have your parent or guardian fill out this {Form}.",
            form: "Form",
            formUrl: "https://puregym.swiss/wp-content/uploads/2021/12/211217_U18_Abo_A4_EN.pdf",
            minors3: "Bring your ID and guardian to your first practice and let's go!",
            sunflat: "Use of the solarium for persons under 18 years not allowed.",
            sunflatAgree: "I agree that I am not allowed to use the solarium because I am a minor.",
        },
        studio: {
            searchPlaceholder: "Where do you want to train",
            clubList: "Club List",
            mapView: "Map view",
        },
        basket: {
            totalAmountFull: "TOTAL AMOUNT",
            totalAmount: "Total",
        },
        user: {
            genderFemale: "Ms",
            genderMale: "Man",
            genderDivers: "Divers",
            firstname: "First name",
            lastname: "Surname",
            email: "E-mail",
            phone: "Phone",
            birthday: "Birthday",
        },
        address: {
            street: "Street",
            streetno: "House number",
            zipcode: "ZIP CODE",
            city: "City",
            country: "Country",
        },
        membership: {
            membership: "Membership",
            startOfMembership: "Your membership start date",
            trainingsStart: "Training starts {date} ",
            rightAway: "now",
        },
    },
    footer: {
        backToHomepage: "Back to Website",
        imprint: "Legal notice",
        dataProtection: "Privacy statement",
        paymentOptions: "Payment options",
    },
    steps: {
        step1_1: "Select club",
        step1_2: "Choose membership",
        step1_3: "Additions",
        step1_4: "Summary",
        step2_1: "Data",
        step2_2: "Address",
        step2_3: "Payment",
        step2_4: "Confirmation",
    },
    pages: {
        failed:{
            headline: "Payment failed",
            intro: "Unfortunately your payment failed or you canceled it.",
            here: "here",
            newOrder: "Click {url} to start a new order."
        },
        selectClub: {
            headline: "Choose your Club",
        },
        selectContract: {
            headline: "Choose subscription",
            choosePlan: "Choose your membership",
            chooseRuntime: "Choose your term",
            basisPlan: "Basic offer",
            aktionPlan: "Special offer",
            premiumPlan: "Best offer",
            friendCode: "FRIEND-Code",
            friendCode2: "Do you have a FRIEND-code? <strong><u>Click here</u></strong>.",
            friendCodeDecline: "The FRIEND-Code {coupon} has been declined.",
            freeTrainer: "Free training from June 15th - 20th, 2022",
            planPoints: [
                "PureGym AG app incl. live load",
                "Fitness On-Demand",
                "Access to all clubs",
                "Bring a friend for free at weekends",
                "Notice period",
                "Pause your membership",
                "Registration fee",
                "Notice period",
                "Annual maintenance fee",
            ],
            tooltips: {
                weekendFriend:
                    'Premium members have the option of bringing an additional person ("Friend") with them to shared use of the facilities on weekends (Saturday and Sunday) at no additional charge. See Terms and Conditions p.6 for further details.',
                freezeMembership:
                    "A justified ´time-stop´ of up to 9 months (for example military service, illness, professional stay abroad) and unfounded ´time-stop´ of up to 3 months per year are permitted. See terms and conditions p.13 for further details.",
                serviceFee: "<strong>What is the annual maintenance fee?\n</strong><br>" +
                    "By charging a once per year annual maintenance fee we are able to:" +
                    "<ul style='padding-left: 1rem; font-size: 0.9rem;'>" +
                    "<li>Constantly reinvest money in new equipment in your gym.</li>" +
                    "<li>Maintain and upgrade the quality of our facilities which benefits our members.</li>" +
                    "</ul>" +
                    "<p>ALL the money we raise from these maintenance fees in this gym will be spent in this gym.</p>" +
                    "<p>For monthly contracts the annual maintenance fee will be added to your fourth monthly payment. For annual contracts the annual maintenance fee will be due with your membership fee.</p>",
            },
        },
        selectAddition: {
            headline: "Make your membership complete",
            unselectAddition: "Do not select extras",
        },
        overview: {
            headline: "Your overview",
            yourData: "Your data",
            voucherHintHeadline:  "Do you have a voucher?",
            voucherHint:  "You can redeem it in the next step.",
        },
        selectData: {
            headline: "Checkout",
            dataProtection: "Data Protection",
            checkboxDataPolice: "I agree to the {DP_URL} from PureGym AG.",
            checkboxNewsletter: "Yes, I would like advertising information and information on other PureGym AG products and its partners.",
            footerActionText: "Please fill in",
            yourData: "Your details",
        },
        selectAddress: {
            headline: "Checkout",
            yourAddress: "Your address",
            yourMembership: "Membership",
            howDoYouKnow: "How do you know us?",
            footerName: "Hello {fname}",
            footerActionText: "State your address",
        },
        selectPayment: {
            headline: "Checkout",
            yourPaymentOptions: "Your payment options",
            //healthInsuranceRefund: "health insurance reimbursement",
            healthInsuranceRefund: "Save yourself healthy",
            checkboxHealthInsuranceRefund: "<p>Yes, I would like to benefit from SWICA's partner offer, up to 25% premium discount and up to CHF 600 refund*, and be contacted. I hereby agree that my personal data can be passed on to SWICA Healthcare Insurance Ltd for advertising purposes and to enable PureGym AG AG to contact me. </p>" +
                "<p><strong>The information that SWICA, our partner, can use for contacting and advisory purposes is limited to data about gender, names, addresses, contact details, and the date of birth. </strong> None of the information obtained from the health questions will be shared.  The member can revoke SWICA's right to use his or her data at any time at <a target='_blank' href='https://www.swica.ch/data-protection'>www.swica.ch/data-protection</a>. *Detailed conditions on the partner page <a target='_blank' href='https://www.swica.ch/puregym'>www.swica.ch/puregym</a></p>",
            checkboxUrlText: "here",
            footerName: "Hallo {fname}",
            footerActionText: "Nur noch ein Schritt",
        },
        checkOut: {
            headline: "Checkout",
            yourOverview: "Your overview",
            yourData: "Your details",
            address: "Address",
            yourMembershipStart: "Your membership start date",
            paymentInformation: "Payment",
            couponRedeem: "Redeem",
            coupon: "Coupon Code",
            couponAccept: "The coupon code {coupon} has been activated.",
            couponDecline: "The coupon code {coupon} has been declined.",
            orderForFee: "Order now",
            orderForFree: "Order now for free",
            checkboxAGB: "I agree to PureGym AG's {url_agb} and  {url_dataprotection}.",
            checkbox: "I agree to PureGym AG's {url_agb} and {url_dataprotection}, and I hereby accept the {houseRules} of PureGym AG.",
            checkbox247: "I agree to PureGym AG's {url_agb}, {url_dataprotection}, {houseRules}  and {unmannedPeriods}.",
            checkboxURLUnmannedPeriods: "Unmanned Periods",
            checkboxURLAGB: "T&C",
            checkContract:
                "Ich stimme zu, dass der Vertrag sich jeweils um weitere 1 Monat zu den allgemeinen Bedingungen verlängert, wenn er nicht mindestens 10 Werktage vor Ablauf durch schriftliche Kündigung aufgehoben wird.",
            checkContract12:
                "Ich stimme zu, dass der Vertrag sich jeweils um weitere 1 Monat zu den allgemeinen Bedingungen verlängert, wenn er nicht mindestens 10 Werktage vor Ablauf durch schriftliche Kündigung aufgehoben wird.",
            checkHouseRules: "I hereby accept the {houseRules} of PureGym AG.",
            houseRules: "house rules",
        },
    },
    fields: {
        fname: "First name",
        lname: "Surname",
        birthday: "Birthday",
        phone: "Phone",
        email: "E-Mail",
        street: "Street",
        streetNo: "House number",
        zipcode: "ZIP CODE",
        city: "City",
    },
    validations: {
        messages: {
            alpha: "The {_field_} field may only contain alphabetic characters",
            alpha_num: "The {_field_} field may only contain alpha-numeric characters",
            alpha_dash: "The {_field_} field may contain alpha-numeric characters as well as dashes and underscores",
            alpha_spaces: "The {_field_} field may only contain alphabetic characters as well as spaces",
            between: "The {_field_} field must be between {min} and {max}",
            confirmed: "The {_field_} field confirmation does not match",
            digits: "The {_field_} field must be numeric and exactly contain {length} digits",
            dimensions: "The {_field_} field must be {width} pixels by {height} pixels",
            email: "The {_field_} field must be a valid email",
            excluded: "The {_field_} field is not a valid value",
            ext: "The {_field_} field is not a valid file",
            image: "The {_field_} field must be an image",
            integer: "The {_field_} field must be an integer",
            length: "The {_field_} field must be {length} long",
            max_value: "The {_field_} field must be {max} or less",
            max: "The {_field_} field may not be greater than {length} characters",
            mimes: "The {_field_} field must have a valid file type",
            min_value: "The {_field_} field must be {min} or more",
            min: "The {_field_} field must be at least {length} characters",
            numeric: "The {_field_} field may only contain numeric characters",
            oneOf: "The {_field_} field is not a valid value",
            regex: "The {_field_} field format is invalid",
            required_if: "The {_field_} field is required",
            required: "The {_field_} field is required",
            size: "The {_field_} field size must be less than {size}KB",
            double: "The {_field_} field must be a valid decimal",
        },
    },
    promo: {
        desktop: "Pay only CHF 10 for your monthly subscription, or CHF 25 for the Premium version: Use promo code <b>PRESENT</b> or <b>PRESENTPREMIUM</b> at the last step in the webshop.",
        mobile: "Use promo code <b>PRESENT</b> for monthly Core subscription at CHF 10 / <b>PRESENTPREMIUM</b> for monthly Premium CHF 25",
    },
    closeInNovember: "Open until the end of November 2021!",
    BernLorraine: "Bern Lorraine: From 25.10.21 Conversion with many upgrades. <a href='/bern-lorraine-upgrade.html'>Read more</a>.",
    coupon: {
        error:{
            invalidCode: "Invalid Promotional Code.",
            invalidStudio: "Promotional code is invalid for this gym.",
            invalidFlat: "Promotional code is invalid for extras.",
            invalidContractType: "Promotional code is invalid for this membership.",
            invalidContractRuntime: "Promotional code is invalid for this term.",
            invalidFlatType: "Promotional code is invalid for this extra.",
        }
    }
};
