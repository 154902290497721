<template>
  <div class="basket flex-grow-1 mb-2 ">
    <div class="h-100 row m-0 align-items-center">
      <ul class="col">
        <li v-for="item in basket.items" :key="item">
          {{item.name}}
          <span class="price">{{item.value | formatCurrency5}}</span>
        </li>
        <li class="line"></li>
        <li>
          <strong>{{ $t('widget.basket.totalAmount')}}</strong>
          <span class="price">{{basket.total | formatCurrency5}}</span>
        </li>
        <li v-if="contract.ServiceBetrag && contract.vertLaufzeit < 6" class="mt-3">
          <strong>{{ $t('pages.selectContract.planPoints.8')}}</strong>
          <span class="price">{{contract.ServiceBetrag | formatCurrency5}}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "Basket",
  computed: mapState(["basket", "contract"]),
}
</script>

<style scoped>

</style>