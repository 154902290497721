<template>
  <div id="app" class="h-100 d-flex flex-column">
    <header>
      <div class="container">
        <div class="row align-items-center">
          <div class="col text-left" id="logo_container">
            <a :href="$root.config.urlHome">
              <img src="/shop/img/puregym_logo.svg">
            </a>

          </div>
          <div class="col text-right" v-if="$root.debug || langsSwitch">
            <div class="btn-group">
              <button type="button" class="btn btn-secondary dropdown-toggle text-uppercase btn-sm" data-toggle="dropdown" aria-expanded="false">
                <i class="fa fa-globe"></i> {{ currentLanguage }}
              </button>
              <div class="dropdown-menu dropdown-menu-right">
                <button v-for="lang in ['de', 'en', 'fr', 'it']" :key="lang" class="dropdown-item text-uppercase" :class="[lang ==  currentLanguage ? 'active' : '']"  @click="$i18n.locale = lang" type="button">{{ lang }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid headerMsgContainer" v-if="activeHeader">
        <div class="row" :class="'headerBrand' + header.brand" v-for="header in activeHeader" :key="header.id">
          <div class="col text-center d-none d-md-block" v-html="header.desktopHeader"></div>
          <div class="col text-center d-md-none" v-html="header.mobileHeader"></div>
        </div>
      </div>

      <!--<div class="container-fluid headerMsgContainer" v-if="isClub(83) || isClub(85) || isDate('2021-10-25', '2021-11-02')">
        <div class="row" v-if="isDate('2021-10-25', '2021-11-02')">
          <div class="col text-center d-none d-md-block" v-html="$t('promo.desktop')"></div>
          <div class="col text-center d-md-none" v-html="$t('promo.mobile')"></div>
        </div>
        <div class="row" v-if="isClub(83)">
          <div class="col text-center">{{ $t('closeInNovember') }}</div>
        </div>
        <div class="row puregym" v-if="isClub(85)">
          <div class="col text-center" v-html="$t('BernLorraine')"></div>
        </div>
      </div>-->
    </header>

    <!--<test2></test2>-->
    <router-view></router-view>


    <hsc-window-style-metal v-if="debug" style="position: fixed; z-index: 99999">
      <hsc-window title="Debug" :resizable="true" :width="200" :height="200">
        <div class="form-group">
          <labeL class="col">Sprache: {{ $i18n.locale }}
            <select class="form-control" v-model="$i18n.locale">
              <option v-for="lang in ['de', 'en', 'fr', 'it']" :key="lang">{{ lang }}</option>
            </select>
          </labeL>
        </div>
        <div class="form-group">
          <labeL class="col">Schritt: {{ currentStep }}
            <select class="form-control" v-model="currentStep">
              <option v-for="step in [1, 2, 3,4 ,5 ,6 ,7,8]" :key="step" :value="step-1" :disabled="step > 4">Schritt
                {{ step }}
              </option>
            </select>
          </labeL>
        </div>

      </hsc-window>
    </hsc-window-style-metal>
    <div v-if="$root.debug" style="padding-bottom: 100px;">
      <vue-json-pretty :data="$store.state">
      </vue-json-pretty>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex"
import moment from "moment/moment";
import axios from "axios";
//import Test2 from "@/components/Test2";
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'

export default {
  name: 'App',
  components: {
    //Test2,
    //'myc': () => import("./Myc")
    VueJsonPretty
  },
  computed: {
    ...mapState(["studio", "contract", "addition"]),
    currentStep: {
      get() {
        return this.$store.state.currentStep;
      },
      set(value) {
        this.$store.commit('SET_STEP', {step: parseInt(value)});
      }
    },
    currentLanguage(){
        return this.$i18n.locale.split('-')[0];
    },
    activeHeader() {

      if (!this.$store.state.headers) return null;
      let headers = this.$store.state.headers;
      let index = null;
      let aHeaders = Array();

      let checkStudio = (header) => {
        if (!header.studios || header.studios.length == 0) {
          return true;
        }

        if (!this.$store.state.studio) {
          return false;
        }


        for (let sIndex in header.studios) {
          if (header.studios[sIndex].id == this.$store.state.studio.id) {
            return true;
          }
        }

        return false;
      }

      let checkContractType = (header) => {
        if (!header.contractType || header.contractType.length == 0) {
          return true;
        }

        if (!this.$store.state.contract) return false;

        for (let sIndex in header.contractType) {
          if (header.contractType[sIndex] == this.$store.state.contract.VertragSubTyp) {
            return true;
          }
        }

        return false;
      };

      let checkContractTerm = (header) => {
        if (!header.contractTerm || header.contractTerm.length == 0) {
          return true;
        }

        if (!this.$store.state.contract) return false;

        for (let sIndex in header.contractTerm) {
          if (header.contractTerm[sIndex] == this.$store.state.contract.vertLaufzeit) {
            return true;
          }
        }

        return false;
      };


      let check = (header) => {
        if (!checkStudio(header)) {
          return false;
        }

        if (!checkContractType(header)) {
          return false;
        }

        if (!checkContractTerm(header)) {
          return false;
        }

        return true;
      };

      for (index in this.$store.state.headers) {
        if (check(headers[index])) {
          aHeaders.push(headers[index]);
        }
      }

      return aHeaders;
      //return this.$store.state.headers;
    }
  },
  data() {
    return {
      debug: false,
      langsSwitch: false,
      steps: [
        {
          headline: "Wähle deinen Club",
          pathname: "home",
          step: 0,
        },
        {
          headline: "Wähle dein Paket",
          pathname: "contract",
          step: 1,
        },
        {
          headline: "Wähle deinen Zusatz",
          pathname: "additional",
          step: 2,
        },
        {
          headline: "Deine Übersicht",
          pathname: "overview",
          step: 3,
        },
        {
          headline: "Kasse",
          pathname: "checkout_data",
          step: 4,
        },
        {
          headline: "Kasse",
          pathname: "checkout_address",
          step: 5,
        },
        {
          headline: "Kasse",
          pathname: "checkout_payment",
          step: 6,
        },
        {
          headline: "Kasse",
          pathname: "checkout_finish",
          step: 7,
        }
      ],
      paymentData: null,
    }
  },
  mounted() {
    if (this.$root.config.preSelectClub != undefined) {
      this.axios.get('api/shop/studio/' + this.$root.config.preSelectClub.toString(), {
        params: {
          lang: this.$i18n.locale,
          tbcId: true
        }
      }).then(res => {
        if (res.data.studio) {
          this.$store.dispatch("setStudio", {studio: res.data.studio});
          this.$store.commit("SET_STEP", {step: 1});
          this.$root.config.preSelectClub = null;
        }
      });
    }


    if (this.$root.config.modus != undefined && this.$root.config.modus == "employerApp") {
      this.steps[0] = {
        headline: "Mitarbeiterlogin",
        pathname: "login",
        step: 0,
      };

      if (this.$root.config.langSwitch != undefined && this.$root.config.langSwitch === true) {
        this.langsSwitch = true;
      }

      this.$store.commit("SET_STEP", {step: 0});
    }

    //console.log(this.$root.config);

    const html = document.documentElement // returns the html tag
    this.$i18n.locale = html.getAttribute('lang');

    if (this.$root.config.modus != undefined && this.$root.config.modus == "employerApp") {
      this.$router.push({name: 'login'});
    }
    else if(this.$route.name != 'failed' && this.$route.name != 'thankyou') {
      this.$router.push({name: 'home'});
    }

    this.axios.get('api/shop/headers', {params: {lang: this.$i18n.locale}}).then(res => {
      if (res.data.headers) {
        this.$store.commit('SET_HEADERS', {headers: res.data.headers});
      } else {
        this.$store.commit('SET_HEADERS', {headers: null});
      }
    });
  },
  watch: {
    '$i18n.locale': function (newVal, oldVal) {
      this.$store.commit("SET_LOCALE", newVal);
    },
    '$route'(to, from) {
      return true;
    },
    studio(newValue, oldValue) {
      //this.stepOption1[0].value = newValue.name;
    },
    currentStep() {
      //this.initSteps();
      if (this.currentStep == undefined) return;
      //this.initSteps();
      this.$router.push({name: this.steps[this.currentStep].pathname});
      window.dataLayer = window.dataLayer || [];

      if (this.$gtm.enabled()) {
        this.$gtm.dataLayer().push({
          "event": "virtualPageView",
          "virtualPageviewLocation": window.location.toString(),
          "virtualPageviewTitle": document.title + " - " + this.steps[this.currentStep].pathname,
        });
      }
    },
  },
  methods: {
    now() {
      return moment();
    },
    isDate(from, to) {
      return moment().isSameOrAfter(from, 'day') && moment().isSameOrBefore(to, 'day');
    },
    isClub($clubid) {
      if (!this.$store.state.studio) return false;
      return this.$store.state.studio.externTbcId == $clubid;
    },
    stepOption1: undefined,
    pay() {
      this.axios.get("api/shop/order/payment").then(res => {
        this.paymentData = res.data;
        setTimeout(() => {
          this.$refs.payment.submit();
        }, 100);

      });
    },

    initSteps() {
      let step = this.currentStep;

      if (this.currentStep < 4) {
        this.currentStepOptions = this.stepOption1;
      } else {
        this.currentStepOptions = this.stepOption2;
      }

      if (this.studio) {
        this.stepOption1[0].value = this.studio.name;
      }

      if (this.contract) {
        this.stepOption1[1].value = this.contract.Bezeichnung;
      }

      if (this.addition) {
        this.stepOption1[2].value = this.addition.bezeichnung_current_language;
      }

      if (step > 0) {
        this.stepOption1[0].active = false;
        this.stepOption1[0].finish = true;
        this.stepOption1[1].active = true;
      }
      if (step > 1) {
        this.stepOption1[1].active = false;
        this.stepOption1[1].finish = true;
        this.stepOption1[2].active = true;
      }
      if (step > 2) {
        this.stepOption1[2].active = false;
        this.stepOption1[2].finish = true;
        this.stepOption1[3].active = true;
      }

      if (step > 3) {
        this.stepOption2[0].active = true;
      }
      if (step > 4) {
        this.stepOption2[0].active = false;
        this.stepOption2[0].finish = true;
        this.stepOption2[1].active = true;
      }
      if (step > 5) {
        this.stepOption2[1].active = false;
        this.stepOption2[1].finish = true;
        this.stepOption2[2].active = true;
      }
      if (step > 6) {
        this.stepOption2[2].active = false;
        this.stepOption2[2].finish = true;
        this.stepOption2[3].active = true;
      }
    },
    onStudioSelect(studio) {
      this.studio = studio;
    },
    onContractSelect(contract) {
      //this.contract = contract;
    },
    onNextStep() {
      this.$store.commit("NEXT_STEP");

    },
    getStepHeadline() {
      return this.steps[this.currentStep].headline;
    },
    getFooterHeadline() {
      switch (this.currentStep) {
        case 0:
          if (this.studio) {
            return this.studio.name;
          }
          break;
        case 1:
          if (this.contract) {
            return "CHF " + this.contract.vertBeitrag;
          }
          break;
        case 2:
          if (this.addition) {
            return "CHF " + this.addition.zusaBeitrag;
          }
          break;
      }

      return null;
    },
    getFooterSubHeadline() {
      switch (this.currentStep) {
        case 1:
          if (this.contract) {
            return this.contract.Bezeichnung;
          }
          break;
        case 2:
          if (this.addition) {
            return this.addition.bezeichnung_current_language;
          }
          break;
      }
      return null;
    },
    getFooterButtonEnabled() {
      switch (this.currentStep) {
        case 0:
          if (this.studio) {
            return true;
          }
          break;
        case 1:
          if (this.contract) {
            return true;
          }
          break;
        case 2:
          return true;
      }

      return false;
    }
  }
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
