import VueRouter from 'vue-router'

import Login from "@/components/S1_P1_Login";
import Studios from "@/components/S1_P1_Studios";
import Contracts from "@/components/S1_P2_Contracts";
import Additions from "@/components/S1_P3_Additions";
import Overview from "@/components/S1_P4_Overview";
import Checkout1 from "@/components/S2_P1_PersonalData";
import Checkout2 from "@/components/S2_P2_Address";
import Checkout3 from "@/components/S2_P3_Payments";
import Checkout4 from "@/components/S2_P4_Checkout";
import ThankYou from "@/components/S3_P1_ThankYou";
import Failed from "@/components/S3_P2_Failed";


const routes = [
    {
        name: 'thankyou',
        path: '/ThankYou',
        component: ThankYou,
        meta:{
            step: 0,
        }
    },
    {
        name: 'failed',
        path: '/failed',
        component: Failed,
        meta:{
            step: 0,
        }
    },
    {
        name: 'login',
        path: '/Login',
        component: Login,
        meta:{
            step: 0,
        }
    },
    {
        name: 'home',
        path: '/DeinClub',
        component: Studios,
        meta:{
            step: 0,
        }
    },
    {
        name: 'contract',
        path: '/DeinPaket',
        component: Contracts,
        meta:{
            step: 1,
        }
    },
    {
        name: 'additional',
        path: '/DeinZusatz',
        component: Additions,
        meta:{
            step: 2,
        }
    },
    {
        name: 'overview',
        path: '/Ubersicht',
        component: Overview,
        meta:{
            step: 3,
        }
    },
    {
        name: 'checkout_data',
        path: '/Daten',
        component: Checkout1,
        meta:{
            step: 4,
        }
    },
    {
        name: 'checkout_address',
        path: '/Adresse',
        component: Checkout2,
        meta:{
            step: 5,
        }
    },
    {
        name: 'checkout_payment',
        path: '/Bezahlung',
        component: Checkout3,
        meta:{
            step: 6,
        }
    },
    {
        name: 'checkout_finish',
        path: '/CheckOut',
        component: Checkout4,
        meta:{
            step: 7,
        }
    },
    { path: '*', redirect: '/DeinClub' }
];

//const router = new VueRouter({routes});

const router = new VueRouter({
    history: false,
    //mode: 'history',
    routes,
});

router.beforeEach((to, from, next) => {
    //if (to.name !== 'login') next({ name: 'login' })
    // if the user is not authenticated, `next` is called twice
    next();
});

export default router;