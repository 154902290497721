<template>
  <div class="vAdditions">
    <h1 class="text-center">{{ $t('pages.selectAddition.headline') }}</h1>
    <steps :steps="[]" />

    <div class="container-fluid container-lg" v-if="!preConditions()">
        <div class="alert alert-danger">
          <strong>Error:</strong> Preconditions not valid

          Preconditions
          <ul>
              <li>select studio</li>
              <li>select contract</li>
          </ul>
        </div>
    </div>

    <div class="container-fluid container-lg" v-if="additions && preConditions()">
      <div class="row row-eq-height ">
        <div class="col-12 col-md-5 p-sm-0">
            <div class="h-100  d-flex flex-column">
              <div class="flex-grow-1 px-1">
                <div class="addition special " :class="{selected: isSelected(special)}" @click="selectAddition(special)" :data-premium="$t('common.bestChoice')">
                  <span class="title">{{ special.bezeichnung_current_language }}</span>
                  <p  style="white-space: pre-line;" >
                    {{special.bullet_current_language}}

                    <i style="display: block; font-weight: bold; padding-top: 10px; font-size: 12px;"><span class="text-primary">*</span> {{$t('widget.miniors.sunflat')}}</i>
                  </p>
                  <span class="price">{{ special.zusaBeitrag | formatCurrency3}}</span>
                  <span class="priceStreich">{{special.subtitle2_current_language}}</span>

                </div>
              </div>

              <img class="img-fluid p-1 d-none d-md-block mt-1" src="/shop/img/aio.jpg">
            </div>
        </div>
        <div class="col-12 col-sm-1 px-1">
          <div class="hr vlr"><span>{{ $t('common.or') }}</span></div>
        </div>
        <div class="col p-sm-0" >
          <div class="row h-100 d-flex flex-row px-3 px-sm-3">
            <div v-for="add in additions" :key="add.id" class="col-12 col-md-6 flex-grow-1 p-1">
              <div class="addition" :class="{selected: isSelected(add)}" @click="selectAddition(add)">
                <span class="title">{{ add.bezeichnung_current_language }} <span v-if="add.Typ == 'Sun'" class="text-primary">*</span> <i>{{add.subtitle_current_language}}</i></span>

                <p style="white-space: pre-line;" >{{ add.bullet_current_language }}
                  <i v-if="add.Typ == 'Sun'" style="display: block; font-weight: bold; padding-top: 10px;"><span class="text-primary">*</span> {{$t('widget.miniors.sunflat')}}</i>
                </p>
                <span class="price">{{ add.zusaBeitrag | formatCurrency3 }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col text-right">
          <a class="link unselect" v-if="addition" @click="selectAddition(null)" href="#">{{  $t('pages.selectAddition.unselectAddition') }}</a>
        </div>
      </div>
      <footer-link-bar></footer-link-bar>
    </div>

    <Footer @clickNext="onNextStep" :button-enabled="footerButton" :headline="footerHeadline()" :subheadline="footerSubHeadline()"></Footer>
  </div>
</template>

<script>
import {mapState} from "vuex";
import Steps from "@/components/StepsPart1";
import Footer from "@/components/Footer";
import FooterLinkBar from "@/components/FooterLinkBar";

export default {
  name: "Additions",
  components: {Steps, Footer, FooterLinkBar},
  data(){
    return{
      additions:null,
      special: null,
    }
  },
  mounted(){
    this.getAdditions();
    this.$store.commit("SET_STEP", {step:2});
  },
  computed: mapState(["studio", "contract", "addition"]),
  watch:{
    contract(){
      this.getAdditions();
    },
    '$i18n.locale': function(newVal, oldVal) {
      this.getAdditions();
    },
  },
  methods:{
    preConditions(){
      if(this.studio === undefined || !this.studio) return false;
      if(this.contract === undefined || !this.contract) return false;

      return true;
    },
    footerButton(){
      return true
    },
    footerHeadline(){
      if(this.addition !== undefined && this.addition){
        return Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF'  }).format(parseFloat(this.addition.zusaBeitrag)).replace(/\D00(?=\D*$)/, '');
      }
      return "CHF 0";
    },
    footerSubHeadline(){
      if(this.addition !== undefined && this.addition){
        return this.addition.bezeichnung_current_language;
      }
      return this.$t('common.noAddition');
    },
    onNextStep() {
      this.$store.commit('SET_STEP', {step: 3});
    },
    isSelected(special){
        if(this.addition){
          return this.addition.id == special.id
        }
        return false;
    },
    selectAddition(add){
        //this.$store.commit("SET_ADDITION", {addition: add});
        this.$store.dispatch("setAddition", {addition: add});
    },
    getAdditions(){
      let url =  "/api/shop/contract/" + this.contract.id + "/additional"  ;
      this.axios.get(url,{params:{lang: this.$i18n.locale}}).then(res => {
        this.additions = res.data.additions;
        this.special = this.additions.pop();
      }).catch(error => {alert("Ein Fehler ist aufgetreten"); });
    },
  }
}
</script>

<style scoped>

</style>