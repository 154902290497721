export default {
    wohlen: "Cours disponibles à partir du 5 juillet",
    common:{
        dateFormat: "DD.MM.YYYY",
        currency:  "CHF {value}",
        choose:     "Choisir",
        next:       "Continuer",
        previous:   "Retour",
        bestChoice: "Le meilleur choix",
        and:        "et",
        or:         "ou",
        edit:       "Modifier",
        basket:     "Panier",
        yourStudio: "Votre club",
        yourPlan:   "Votre abonnement",
        yourAddition: "Vos extras",
        noAddition: "Continuer sans extra",
        days : "Jour | 1 jour | {count} jours",
        months : "Mois | 1 mois | {count} mois",
        checkout: "À la caisse",
        abort: "annuler",
        opening: "Ouverture {date}",
        employerLogin: "Connexion employé",
        username: "Nom d'utilisateur",
        password: "le mot de passe",
    },
    widget:{
        miniors: {
            headline: "Mineurs",
            minors1: "Vous n'êtes pas encore majeure?",
            minors2: "Demandez à votre parent ou tuteur de remplir ce {Formulaire}",
            form: "Formulaire",
            formUrl: "https://puregym.swiss/wp-content/uploads/2021/12/211217_U18_Abo_A4_FR.pdf",
            minors3: "Apportez votre carte d'identité et votre tuteur à votre 1er entraînement et c'est parti!",
            sunflat: "Utilisation du solarium interdite aux personnes de moins de 18 ans.",
            sunflatAgree: "J'accepte de ne pas utiliser le solarium car je suis mineur.",
        },
        studio:{
          searchPlaceholder : "Où voulez-vous vous entraîner?",
          clubList : "Liste des clubs",
          mapView : "Vue de la carte"
        },
        basket:{
            totalAmountFull: "MONTANT TOTAL",
            totalAmount: "Total",
        },
        user:{
          genderFemale : "Mme.",
          genderMale : "Homme",
          genderDivers : "Divers",
          firstname : "Prénom",
          lastname : "Nom de famille",
          email : "E-mail",
          phone : "Téléphoner",
          birthday : "Anniversaire.",
        },
        address:{
          street : "Rue",
          streetno : "Numéro de maison",
          zipcode : "NPA",
          city : "Ville",
          country : "Pays",
        },
        membership:{
          membership : "Adhésion",
          startOfMembership : "Votre adhésion commence",
          trainingsStart : "Début formation {date} ",
          rightAway : "immédiatement",
        }
    },
    footer:{
          backToHomepage : "Retour sur le site",
          imprint : "Mentions légales",
          dataProtection : "Vie privée",
          paymentOptions : "Paiement",
        },
    steps: {
          step1_1 : 'Choisir club',
          step1_2 : "Choisir l'adhésion",
          step1_3 : 'Ajouts',
          step1_4 : 'Résumé',
          step2_1 : 'Données',
          step2_2 : 'Adresse',
          step2_3 : "Paiement",
          step2_4 : "Confirmation",
    },
    pages:{
        failed:{
            headline: "Erreur de paiement",
            intro: "Malheureusement, ton paiement a été rejeté ou tu l'as annulé.",
            here: "Ici",
            newOrder: "{url}, tu peux commencer une nouvelle commande."
        },
        selectClub:{
            headline: "Choisissez un club",
        },
        selectContract: {
            headline: "Choisissez votre adhésion",
            choosePlan: "Choisissez votre adhésion",
            chooseRuntime: "Choisissez votre terme",
            basisPlan : "Offre de base",
            aktionPlan: "Offre spéciale",
            premiumPlan : "Meilleure offre",
            friendCode: "FRIEND-Code",
            friendCode2: "Avez-vous un FRIEND-code? <strong><u>Alors cliquez ici</u></strong>.",
            friendCodeDecline: "Le FRIEND-Code de {coupon} a été refusé.",
            freeTrainer: "Formation gratuite du du 15 au 20 juin 2022",
            planPoints: [
                    "App PureGym AG (utilisation en temps réel)",
                    "Fitness On-Demand",
                    "Accès à tous les clubs",
                    "Un ami s'entraîne gratuitement le week-end",
                    "Période d'annulation",
                    "Mettez votre adhésion en pause",
                    "Frais d'inscription",
                    "Période d'annulation",
                    "Redevance annuelle d'entretien",
            ],
            tooltips:{
                weekendFriend: "Les membres Premium ont la possibilité d'amener une personne supplémentaire (\"ami\") avec eux pour partager l'utilisation des installations le week-end (samedi et dimanche) sans frais supplémentaires. Voir les conditions générales p.6 pour plus de détails.",
                freezeMembership: "A justified ´time-stop´ of up to 9 months (for example military service, illness, professional stay abroad) and unfounded ´time-stop´ of up to 3 months per year are permitted. See terms and conditions p.13 for further details.",
                serviceFee: "<strong>À QUOI CORRESPONDENT LES FRAIS DE MAINTENANCE ANNUELS?</strong><br>" +
                    "En facturant une fois par an des frais de maintenance, nous sommes en mesure:" +
                    "<ul style='padding-left: 1rem; font-size: 0.9rem;'>" +
                    "<li>d’investir constamment de l’argent dans de nouveaux équipements pour votre studio de fitness.</li>" +
                    "<li>de maintenir et d’améliorer la qualité des installations, ce qui profite à nos membres.</li>" +
                    "</ul>" +
                    "<p>Tout l’argent perçu dans ce studio de fitness grâce aux frais de maintenance est réinvesti dans le studio de fitness.</p>" +
                    "<p>Pour les contrats mensuels, les frais de maintenance annuels viennent s’ajouter à votre quatrième versement. Pour les contrats annuels, les frais de maintenance annuels sont exigibles en même temps que l’adhésion.</p>",
            }
        },
        selectAddition:{
            headline: "Rendre votre abonnement complèt",
            unselectAddition: "Continuer sans extra",
        },
        overview:{
            headline: "Votre résumé",
            yourData: "Vos données",
            voucherHintHeadline:  "Avez-vous un coupon?",
            voucherHint:  "Vous pouvez l’utiliser à l’étape suivante.",
        },
        selectData:{
            headline: "Checkout",
            dataProtection: "protection des données",
                checkboxDataPolice: "J'accepte les {DP_URL} de PureGym AG.",
                checkboxNewsletter: "Oui, je souhaite des infos publicitaires et des informations sur d'autres produits PureGym AG et ses partenaires.",
                footerActionText: "Veuillez remplir",
                yourData: "Vos données",
            },
        selectAddress:{
            headline: "Checkout",
            yourAddress: "Votre Adresse",
            yourMembership: "Adhésion",
            howDoYouKnow: "Comment vous nous connaissez?",
            footerName: "Bonjour {fname}",
            footerActionText: "Veuillez entrer votre adresse",
            },
            selectPayment:{
                headline: "Checkout",
                yourPaymentOptions: "Vos modes de paiement",
                //healthInsuranceRefund: "Remboursement de l'assurance",
                healthInsuranceRefund: "Préservez votre santé",
                checkboxHealthInsuranceRefund: "<p>Oui, je souhaite bénéficier de l'offre partenaire de SWICA, jusqu'à 25% de réduction sur les primes et jusqu'à CHF 600 de remboursement*, et être contacté. J'accepte que mes données personnelles soient transmises par PureGym AG à SWICA Krankenversicherung AG à des fins publicitaires et de prise de contact.</p>" +
                    "<p><strong>Dans le cadre de la prise de contact à des fins publicitaires de notre partenaire SWICA, le transfert de données se limite au sexe, au nom, à l'adresse et aux coordonnées ainsi qu'à la date de naissance.</strong> Aucune information relative à des questions de santé ne sera transmise. Le membre peut en tout temps révoquer l'utilisation de ses données par SWICA à l'adresse <a target='_blank' href='https://www.swica.ch/protection-des-donnees'>www.swica.ch/protection-des-donnees</a>. *Conditions détaillées sur la page partenaire <a target='_blank' href='https://www.swica.ch/puregym'>www.swica.ch/puregym</a></p>",
                checkboxUrlText: "ici",
                footerName: "Hallo {fname}",
                footerActionText: "Nur noch ein Schritt",
            },
        checkOut:{
            headline: "Checkout",
            yourOverview: "Votre aperçu",
            yourData: "Votre détails",
            address: "Adresse",
            yourMembershipStart : "Votre adhésion commence",
            paymentInformation : "Paiement",
            couponRedeem:  "ajouter",
            coupon:    "Code promo",
            couponAccept: "Le code du bon {coupon} est accepté.",
            couponDecline: "Le code de {coupon} a été refusé.",
            orderForFee : "Acheter maintenant",
            orderForFree: "Commander gratuitement maintenant",
            checkboxAGB: "J'accepte les {url_agb} la {url_dataprotection} de PureGym AG.",
            checkboxURLAGB: "conditions générales",
            checkbox: "J'accepte les {url_agb}, la {url_dataprotection} et le {houseRules} de PureGym AG.",
            checkbox247: "J’accepte les {url_agb}, la {url_dataprotection}, le {houseRules}  et {unmannedPeriods} de PureGym AG.",
            checkboxURLUnmannedPeriods: "les périodes sans personnel",
            checkContract: "Ich stimme zu, dass der Vertrag sich jeweils um weitere 1 Monat zu den allgemeinen Bedingungen verlängert, wenn er nicht mindestens 10 Werktage vor Ablauf durch schriftliche Kündigung aufgehoben wird.",
            checkContract12: "Ich stimme zu, dass der Vertrag sich jeweils um weitere 1 Monat zu den allgemeinen Bedingungen verlängert, wenn er nicht mindestens 10 Werktage vor Ablauf durch schriftliche Kündigung aufgehoben wird.",
            checkHouseRules: "J'accepte le {houseRules} de PureGym AG.",
            houseRules: "règlement intérieur",
        },
    },
    fields:{
        fname : "Prénom",
        lname : "Nom de famille",
        birthday: "Anniversaire",
        phone: "Téléphoner",
        email: "E-mail",
        street: "Rue",
        streetNo: "Numéro",
        zipcode: "NPA",
        city: "Ville",
    },
    validations: {
        "messages": {
            "alpha": "Le champ {_field_} ne peut contenir que des lettres",
            "alpha_num": "Le champ {_field_} ne peut contenir que des caractères alpha-numériques",
            "alpha_dash": "Le champ {_field_} ne peut contenir que des caractères alpha-numériques, tirets ou soulignés",
            "alpha_spaces": "Le champ {_field_} ne peut contenir que des lettres ou des espaces",
            "between": "Le champ {_field_} doit être compris entre {min} et {max}",
            "confirmed": "Le champ {_field_} ne correspond pas",
            "digits": "Le champ {_field_} doit être un nombre entier de {length} chiffres",
            "dimensions": "Le champ {_field_} doit avoir une taille de {width} pixels par {height} pixels",
            "email": "Le champ {_field_} doit être une adresse e-mail valide",
            "excluded": "Le champ {_field_} doit être une valeur valide",
            "ext": "Le champ {_field_} doit être un fichier valide",
            "image": "Le champ {_field_} doit être une image",
            "integer": "Le champ {_field_} doit être un entier",
            "length": "Le champ {_field_} doit contenir {length} caractères",
            "max_value": "Le champ {_field_} doit avoir une valeur de {max} ou moins",
            "max": "Le champ {_field_} ne peut pas contenir plus de {length} caractères",
            "mimes": "Le champ {_field_} doit avoir un type MIME valide",
            "min_value": "Le champ {_field_} doit avoir une valeur de {min} ou plus",
            "min": "Le champ {_field_} doit contenir au minimum {length} caractères",
            "numeric": "Le champ {_field_} ne peut contenir que des chiffres",
            "oneOf": "Le champ {_field_} doit être une valeur valide",
            "regex": "Le champ {_field_} est invalide",
            "required": "Le champ {_field_} est obligatoire",
            "required_if": "Le champ {_field_} est obligatoire lorsque {target} possède cette valeur",
            "size": "Le champ {_field_} doit avoir un poids inférieur à {size}KB",
            "double": "Le champ {_field_} doit être une décimale valide"
        }
    },
    promo: {
        desktop: "Ne payez que CHF 10 pour votre abonnement mensuel ou CHF 25 pour la version Premium: Utilisez le code promo <b>PRESENT</b> ou <b>PRESENTPREMIUM</b> à la dernière étape de la boutique en ligne.",
        mobile: "Utilisez le code promo <b>PRESENT</b> pour un abonnement mensuel Core à CHF 10 / PRESENTPREMIUM pour un <b>Premium</b> mensuel CHF 25",
    },
    closeInNovember: "Ouvert jusqu'à la fin novembre 2021!",
    BernLorraine: "Bern Lorraine: Du 25.10.21 Conversion avec de nombreuses améliorations. <a href='/bern-lorraine-upgrade.html'>Lire la suite</a>.",
    coupon: {
        error:{
            invalidCode: "Code promotionnel invalide.",
            invalidStudio: "Code promotionnel invalide pour cette salle de sport.",
            invalidFlat: "Code promotionnel invalide pour les extras.",
            invalidContractType: "Code promotionnel invalide pour cette adhésion.",
            invalidContractRuntime: "Code promotionnel invalide pour cette durée.",
            invalidFlatType: "Code promotionnel invalide pour cet extra.",
        }
    }
};
